/* eslint-disable */

/* ========================================================================= */
/*	Essential Plugins
/* ========================================================================= */

/*
 *  'js' is an alias for /src/assets/js
 */

// [!] -> Main scripts
//const firebase = require('src/plugins/firebase');
import firebase from "@/plugins/firebase";

const plugins = () => [

    firebase
]

export default plugins;
