/* eslint-disable */

export default function auth ({ to, from, store, next }) {

	if (!store.getters['auth/authenticated']) {

		return next({

			/**
			 * Ideal purpose here is to make a force-refresh (ctrl+F5),
			 * so the app automatically authenticates itself...
			 */
			name: 'Home'
		})
	}

	return next()
}
