<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            {{ $t('breadcrumb.contact.title') }}
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('breadcrumb.start') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/contact">
                                    {{ $t('breadcrumb.contact.target') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section class="row_am contact_list_section">
            <div class="container">

                <div class="contact_list_inner" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
                    <!-- card -->
                    <div class="c_list_card">
                        <div class="icons">
                            <img :src="mail" alt="image">
                            <div class="dot_block">
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                            </div>
                        </div>
                        <div class="inner_text">
                            <h3>
                                {{ $t('views.contact.small-boxes.email.title') }}
                            </h3>
                            <p>
                                {{ $t('views.contact.small-boxes.email.sub-title') }}
                            </p>
                            <a :href="'mailto:' + company.contact.email.info" class="text_btn">
                                {{ company.contact.email.info }}
                            </a>
                        </div>
                    </div>
                    <!-- card -->
                    <div class="c_list_card">
                        <div class="icons">
                            <img :src="location" alt="image">
                            <div class="dot_block">
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                            </div>
                        </div>
                        <div class="inner_text">
                            <h3>
                                {{ $t('views.contact.small-boxes.visit.title') }}
                            </h3>
                            <p>
                                {{ $t('views.contact.small-boxes.visit.sub-title') }}
                            </p>
                            <template v-for="location in company.locations">
                                <a href="#" class="text_btn" v-if="location.is_main_branch === 1" :key="location.uid">
                                    {{ location.address.address1 + ", " + location.address.zip + " " + location.address.city }}
                                </a>
                            </template>
                        </div>
                    </div>
                    <!-- card -->
                    <div class="c_list_card">
                        <div class="icons">
                            <img :src="phone" alt="image">
                            <div class="dot_block">
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                                <span class="dot_anim"></span>
                            </div>
                        </div>
                        <div class="inner_text">
                            <h3>
                                {{ $t('views.contact.small-boxes.call.title') }}
                            </h3>
                            <p>
                                {{ $t('views.contact.small-boxes.call.sub-title') }}
                            </p>
                            <a :href="'tel:' + company.contact.phone" class="text_btn">
                                {{ company.contact.phone }}
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="contact_form_section">
            <div class="container">
                <div class="contact_inner">
                    <div class="contact_form">
                        <div class="section_title">
                            <h2>
                                {{ $t('views.contact.form.title') }}
                            </h2>
                            <p>
                                {{ $t('views.contact.form.sub-title') }}
                            </p>
                        </div>
                        <form @submit.prevent="submit">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" :placeholder="$t('views.contact.form.placeholders.first-name')" v-model="form.firstName">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" :placeholder="$t('views.contact.form.placeholders.last-name')" v-model="form.lastName">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" :placeholder="$t('views.contact.form.placeholders.mobile')" v-model="form.phone">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" :placeholder="$t('views.contact.form.placeholders.email')" v-model="form.email">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" :placeholder="$t('views.contact.form.placeholders.subject')" v-model="form.title">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <textarea name="form_message" class="form-control" :placeholder="$t('views.contact.form.placeholders.message')" v-model="form.message"></textarea>
                            </div>

                            <div class="form-group term_check">
                                <input type="checkbox" id="term">
                                <label for="term">
                                    {{ $t('views.contact.form.confirm-sending-message') }}
                                </label>
                            </div>

                            <div class="form-group ">
                                <button class="btn btn_main" type="submit">
                                    <template v-if="isLoading">
                                        <ring-spinner/>
                                    </template>
                                    <template v-else>
                                        {{ $t('buttons.send-message') }} <i class="icofont-arrow-right"></i>
                                    </template>
                                </button>
                            </div>

                        </form>

                        <div class="form-graphic">
                            <img :src="paperPlane" alt="image">
                        </div>
                    </div>


                </div>
            </div>
        </section>

        <section class="row_am map_section">
            <div class="container">
                <template v-for="location in company.locations">
                    <div class="map_inner">
                        <iframe v-if="location.is_main_branch === 1" :src="location.google_maps_iframe" width="100%" height="510" style="border:0" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade" aria-hidden="false"></iframe>
                    </div>
                </template>
            </div>
        </section>

    </div>

    <prime-toast/>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapActions, mapGetters } from "vuex"

    // '~' is an alias for 'src/components'
    import Navigation   from "~/NavigationComponent";

    // 'images' is an alias for 'src/assets/images'
    import paperPlane   from 'images/overlays/overlay_paper_plane.png'

    // 'icons' is an alias for 'src/assets/icons'
    import mail         from 'icons/ic_contact_mail.png'
    import location     from 'icons/ic_contact_location.png'
    import phone        from 'icons/ic_contact_phone.png'

    export default {
        name: "Contact",
        head: {

            title: 'Mangler du en kørelærer 🤓 Giv et ring og lad os hook up 👋',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {

            return {

                paperPlane,
                mail,
                location,
                phone,

                form: {
                    firstName:  '',
                    lastName:   '',
                    email:      '',
                    phone:      '',
                    title:      '',
                    message:    '',
                }

            };

        },
        computed: {

            ...mapGetters({
                company: 'drivingSchool/getDrivingSchool',
                token: 'auth/authenticated',
                isLoading: 'loading/status'
            })

        },
        methods: {

            ...mapActions({
                loading: 'loading/setLoading',
                sendMessage: 'contact/sendForm'
            }),

            submit() {

                const payload = {
                    token: this.token,
                    form: this.form,
                };

                // validate form inputs here

                // set loading til true
                this.loading(true)

                this.sendMessage(payload)

                    .then(() => {

                        // set loading til false igen
                        this.loading(false)

                        // show SUCCESS toast
                        this.$toast.add({

                            severity:   'success',
                            summary:    this.$t('views.contact.form.toast.success.summary'),
                            detail:     this.$t('views.contact.form.toast.success.detail'),
                            life:       3000
                        });

                    })

                    .catch(() => {

                        // show FAIL toast
                        this.$toast.add({

                            severity:   'error',
                            summary:    this.$t('views.contact.form.toast.fail.summary'),
                            detail:     this.$t('views.contact.form.toast.fail.detail'),
                            life:       3000
                        });

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>