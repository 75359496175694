<!--eslint-disable-->
<template>

    <section class="row_am pricing_section" id="pricing" data-aos="fade-in" data-aos-duration="1000">
        <div class="container">
            <div class="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <h2>
                    {{ $t('components.prices.heading') }}
                </h2>
                <p>
                    {{ $t('components.prices.sub-title') }}
                </p>
            </div>
            <!-- toggle button -->
            <div class="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                <span class="rate" :class="toggle === 'rate' ? 'active' : ''">
                    {{ $t('components.payment-plans.rate') }}
                </span>
                <div class="tog_block" @click="togglePrices">
                    <span class="tog_btn" :class="toggle === 'full' ? 'month_active' : ''"></span>
                </div>
                <span class="full" :class="toggle === 'full' ? 'active' : ''">
                    {{ $t('components.payment-plans.full') }}
                </span>
                <span class="offer">
                    {{ $t('components.payment-plans.save') }}
                </span>
            </div>

            <div class="pricing_panel monthly_plan" :class="toggle === 'rate' ? 'active' : ''">
                <div class="row">

                    <!-- Price Collection box -->
                    <div v-for="(collection, index) in collections" :key="index" class="col-md-4">
                        <div class="pricing_block" :class="collection.recommended ? 'highlited_block' : 'blockwhite'">
                            <div class="icon">
                                <template v-for="(icon, id) in icons" :key="id">
                                    <img v-if="id === index" :src="icon" alt="image">
                                </template>
                                <div class="dot_block">
                                    <span class="dot_anim"></span>
                                    <span class="dot_anim"></span>
                                    <span class="dot_anim"></span>
                                </div>
                            </div>
                            <div class="pkg_name">
                                <template v-for="meta in collection.meta">
                                    <template v-if="meta.i18n === $i18n.locale">
                                        <h3>
                                            {{ meta.title }}
                                        </h3>
                                        <span>
                                            {{ meta.subtitle }}
                                        </span>
                                    </template>
                                </template>
                            </div>
                            <span class="price">
                                {{ $filter.price(collection.sales_price_incl_moms) }}<span>DKK</span>
                            </span>
                            <ul class="benifits">
                                <template v-for="item in collection.items" v-if="collection.items">
                                    <li :class="item.included ? 'include' : 'exclude'">
                                        <p>
                                            <i :class="item.included ? 'icofont-check-circled' : 'icofont-close-circled'"></i>
                                            <template v-for="meta in item.meta">
                                                <span v-if="meta.i18n === $i18n.locale" class="ml-2">
                                                    {{ meta.label }}
                                                </span>
                                            </template>
                                        </p>
                                    </li>
                                </template>
                            </ul>
                            <a href="#" class="btn btn_main">
                                {{ $filter.capitalize($t('buttons.select')) }} <i class="icofont-arrow-right"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="pricing_panel yearly_plan" :class="toggle === 'full' ? 'active' : ''">
                <div class="row">

                    <!-- Price Collection box -->
                    <div v-for="(collection, index) in collections" :key="index" class="col-md-4">
                        <div class="pricing_block" :class="collection.recommended ? 'highlited_block' : 'blockwhite'">
                            <div class="icon">
                                <template v-for="(icon, id) in icons" :key="id">
                                    <img v-if="id === index" :src="icon" alt="image">
                                </template>
                                <div class="dot_block">
                                    <span class="dot_anim"></span>
                                    <span class="dot_anim"></span>
                                    <span class="dot_anim"></span>
                                </div>
                            </div>
                            <div class="pkg_name">
                                <template v-for="meta in collection.meta">
                                    <template v-if="meta.i18n === $i18n.locale">
                                        <h3>
                                            {{ meta.title }}
                                        </h3>
                                        <span>
                                            {{ meta.subtitle }}
                                        </span>
                                    </template>
                                </template>
                            </div>
                            <span class="price">
                                    {{ $filter.price(collection.sales_price_incl_moms - collection.save_amount_in_dkk) }}<span>DKK</span>
                                </span>
                            <ul class="benifits">
                                <template v-for="item in collection.items" v-if="collection.items">
                                    <li :class="item.included ? 'include' : 'exclude'">
                                        <p>
                                            <i :class="item.included ? 'icofont-check-circled' : 'icofont-close-circled'"></i>
                                            <template v-for="meta in item.meta">
                                                <span v-if="meta.i18n === $i18n.locale" class="ml-2">
                                                    {{ meta.label }}
                                                </span>
                                            </template>
                                        </p>
                                    </li>
                                </template>
                            </ul>
                            <a href="#" class="btn btn_main align_bottom">
                                {{ $filter.capitalize($t('buttons.select')) }} <i class="icofont-arrow-right"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </section>

</template>
<!--eslint-disable-->
<script>

import { mapActions, mapGetters } from "vuex";

    // 'icons' is an alias for 'src/assets/icons'
    import paperPlane   from 'icons/ic_paper_plane.svg'
    import rocket       from 'icons/ic_rocket.png'
    import airPlane     from 'icons/ic_air_plane.svg'

    export default {
        name: "Prices",
        components: {

            // ...

        },
        created() {

            this.populateProductCollections(this.token)

        },
        mounted() {

            // ...

        },
        data() {
            return {

                icons:
                    [
                        paperPlane,
                        rocket,
                        airPlane
                    ],

                showDiscount: false,
                toggle: "full"

            }
        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                collections: 'products/getCollections'
            })

        },
        methods: {

            ...mapActions({

                getProductCollections: 'products/fetchProductCollections'
            }),

            populateProductCollections(token) {

                this.getProductCollections(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Product Collections state populated from component")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching product collections (catch) failed")

                    })

            },

            togglePrices(event) {

                console.log(event)

                this.showDiscount = !this.showDiscount;

                if (this.showDiscount) {

                    //
                    this.toggle = "rate"
                    console.log("toggle: " + this.toggle)

                } else {

                    //
                    this.toggle = "full"
                    console.log("toggle: " + this.toggle)

                }
            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...
    .align_bottom {

        bottom: 30px;
        position: absolute;
    }

</style>