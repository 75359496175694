/* eslint-disable */
import axios from "axios"

const ERROR_MESSAGE = 'ERROR_MESSAGE'

export default {

    namespaced: true,

    state : {
        
        courses: [],
        error: {}
    },

    actions : {
        
        async fetchCourses({ commit }, token) {
            
            let response = await axios
                
                .get('/get/courses', {
                    
                    headers: {
                        Authorization: "Bearer " + token
                    },
                    params: {
                        "future": true
                    }
                })
                
                .then(function (response) {
                    
                    commit('saveCourses', response.data)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {

                    commit("saveCourses", null)

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },

        async signupToCourse({ commit }, payload) {

            let form = {

                first_name:             payload.form.firstName,
                last_name:              payload.form.lastName,
                email:                  payload.form.email,
                tel_mobile:             payload.form.phone,
                course_of_interests:    _.isObject(payload.form.courseOfInterests) ? payload.form.courseOfInterests.uid : payload.form.courseOfInterests,
                message:                payload.form.message
            };

            let response = await axios

                .post('/register-student/course/' + form.course_of_interests,

                    {

                        first_name: form.first_name,
                        last_name:  form.last_name,
                        email:      form.email,
                        tel_mobile: form.tel_mobile,
                        message:    form.message

                    },

                    {

                        headers: {
                            Authorization: "Bearer " + payload.token,
                        }
                    }
                )

                .then(function (response) {

                    commit(ERROR_MESSAGE, null)

                })

                .catch(function (error) {

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },
        
    },

    getters : {
        
        getCourses(state) {

            return state.courses
        },
        
        getCourseDetails: (state) => (uid) => {
            
            return state.courses.find(course => course.uid === uid)
        },

        error(state) {

            return state.error
        }
    },

    mutations : {

        [ERROR_MESSAGE]: function (state, payload) {
            state.error = payload
        },
        
        saveCourses(state, payload) {
            state.courses = payload
        }
    }
}
