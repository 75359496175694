<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            {{ $t('breadcrumb.blog.title') }}
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('breadcrumb.start') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/blog">
                                    {{ $t('breadcrumb.blog.target') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section class="blog_list_section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <!-- Blog Left Side -->
                        <div class="blog_left_side">

                            <!-- Blog story -->
                            <story v-for="(story, index) in stories" :story="story" :key="index"/>

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <!-- blog Right Side -->
                        <div class="blog_right_side">
                            <!-- Search Blog -->
                            <div class="blog_search_block bg_box" data-aos="fade-up" data-aos-duration="1500">
                                <form>
                                    <div class="form-group">
                                        <h3>
                                            {{ $t('views.blog.search.title') }}
                                        </h3>
                                        <div class="form_inner">
                                            <input type="text" class="form-control">
                                            <button>
                                                <i class="icofont-search-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- Recent Post -->
                            <div class="recent_post_block bg_box" data-aos="fade-up" data-aos-duration="1500">
                                <h3>
                                    {{ $t('views.blog.recent-posts.title') }}
                                </h3>
                                <ul class="recent_blog_list">
                                    <template v-for="blog in latestBlogs">
                                        <li>
                                            <router-link :to="{ name: 'BlogDetails', params: { id: blog.id }}">
                                                <div class="img">
                                                    <img :src="blog.gallery.thumbnail ? blog.gallery.thumbnail : placeholderSide" alt="image">
                                                </div>
                                                <div class="text">
                                                    <h4>
                                                        <template v-for="meta in blog.meta">
                                                            <template v-if="meta.i18n === $i18n.locale">
                                                                {{ $filter.toUpper(meta.title) }}
                                                            </template>
                                                        </template>
                                                    </h4>
                                                    <span>
                                                        {{ daysSincePublished(blog) + " " + $tc('views.blog.recent-posts.days-ago', daysSincePublished(blog)) }}
                                                    </span>
                                                </div>
                                            </router-link>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <!-- Categories List TODO -->
<!--                            <div class="categories_block bg_box" data-aos="fade-up" data-aos-duration="1500">-->
<!--                                <h3>-->
<!--                                    {{ $t('views.blog.category.title') }}-->
<!--                                </h3>-->
<!--                                <ul>-->
<!--                                    <li>-->
<!--                                        <a href="#" class="cat">-->
<!--                                            <i class="icofont-folder-open"></i> Software-->
<!--                                        </a>-->
<!--                                        <span>-->
<!--                                            (15)-->
<!--                                        </span>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="#" class="cat">-->
<!--                                            <i class="icofont-folder-open"></i> Technology-->
<!--                                        </a>-->
<!--                                        <span>-->
<!--                                            (12)-->
<!--                                        </span>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="#" class="cat">-->
<!--                                            <i class="icofont-folder-open"></i> Business-->
<!--                                        </a>-->
<!--                                        <span>-->
<!--                                            (09)-->
<!--                                        </span>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="#" class="cat">-->
<!--                                            <i class="icofont-folder-open"></i> Web Development</a>-->
<!--                                        <span>(25)</span>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="#" class="cat"><i class="icofont-folder-open"></i> Android</a>-->
<!--                                        <span>(19)</span>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="#" class="cat"><i class="icofont-folder-open"></i> iOS</a>-->
<!--                                        <span>(08)</span>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href="#" class="cat"><i class="icofont-folder-open"></i> Watch</a>-->
<!--                                        <span>(13)</span>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
                            <!-- Tags Block -->
                            <div class="tags_block bg_box" data-aos="fade-up" data-aos-duration="1500">
                                <h3>
                                    {{ $t('views.blog.tags.title') }}
                                </h3>
                                <ul>
                                    <template v-for="tag in tags">
                                        <li v-for="meta in tag.meta">
                                            <a v-if="meta.i18n === $i18n.locale" href="#">
                                                {{ meta.tag_label }}
                                            </a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <paginator v-if="meta.current_page" :meta="meta" v-on:pagination:switched="switchPage"/>

            </div>
        </section>

    </div>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapActions, mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Navigation from "~/NavigationComponent";
    import Story from "~/blog/StoryComponent";
    import Paginator from "~/PaginatorComponent";

    // 'images' is an alias for 'src/assets/images'
    import placeholderImage from "images/img_blog_01.png";
    import placeholderSide from "images/img_blog_side_01.png";

    export default {
        name: "Blog",
        head: {

            title: 'Se relevante nyheder - Køreskolen holder dig up-to-date 📰',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation,
            Story,
            Paginator

        },
        created() {

            this.blogs(this.token)

        },
        mounted() {

            // ...alert("Obs! Denne side er under udvikling ☝️")

        },
        data() {

            return {

                placeholderImage,
                placeholderSide,

                story: {},
                pagination: {}

            };

        },
        watch: {

            '$route.query' (query) {

                this.blogs(query.page)
            }

        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                stories: 'blogs/getStories',
                meta: 'blogs/getMeta',
                tags: 'tags/getTags',
            }),

            latestBlogs() {

                let blogs = this.stories

                if (blogs) {

                    return blogs.slice(0, 5)
                }

            }

        },
        methods: {

            ...mapActions({

                getStories: 'blogs/fetchStories'
            }),

            blogs(page = this.$route.query.page) {

                const payload = {
                    token: this.token,
                    page: page,
                };

                let getBlogs = this.getStories(payload)

            },

            switchPage(page) {

                this.$router.replace({

                    name: 'Blog',
                    query: {

                        page
                    }
                })
            },

            daysSincePublished(blog) {

                let publishDate = new Date(blog.created_at);
                let currentDate = new Date();

                // To calculate the time difference of two dates
                let differenceInTime = currentDate.getTime() - publishDate.getTime();

                // To calculate the no. of days between two dates
                return Math.round(differenceInTime / (1000 * 3600 * 24));
            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>