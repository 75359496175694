<!--eslint-disable-->
<template>

    <section class="row_am latest_story" id="blog">
        <div class="container">
            <div class="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                <h2>
                    {{ $t('components.future-courses.heading') }}
                </h2>
                <p>
                    {{ $t('components.future-courses.sub-title') }}
                </p>
            </div>
            <div class="row">

                <!-- course -->
                <course v-for="(course, index) in courses" :course="course" :key="index"/>

            </div>
        </div>
    </section>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapActions, mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Course from "~/courses/FutureCourseComponent";

    export default {
        name: "FutureCourses",
        components: {

            Course

        },
        created() {

            this.populateCourses(this.token)

        },
        mounted() {

            // ...

        },
        data() {
            return {

                course: {}

            }
        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                courses: 'courses/getCourses'
            })

        },
        methods: {

            ...mapActions({

                getCourses: 'courses/fetchCourses'
            }),

            populateCourses(token) {

                this.getCourses(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Courses state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching courses (catch) failed")

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>