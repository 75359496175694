<!--eslint-disable-->
<template>

    <header class="fixed header-border" :class="{ 'fix_style': sticky }">
        <div class="container">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" v-on:click="goToTop">
                    <img :src="companyLogo" alt="tune trafikskole logo" >
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon">
                        <!-- <i class="icofont-navigation-menu ico_menu"></i> -->
                        <div class="toggle-wrap">
                            <span class="toggle-bar"></span>
                        </div>
                    </span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">

                        <li class="nav-item">
                            <router-link class="nav-link" to="/home">
                                {{ $t('links.home') }}
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link" to="/course/start">
                                {{ $t('links.course-start') }}
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link" to="/prices">
                                {{ $t('links.prices') }}
                            </router-link>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link" to="/about">
                                {{ $t('links.about') }}
                            </router-link>
                        </li>

                        <li class="nav-item has_dropdown">
                            <span class="nav-link">
                                {{ $t('links.info') }}
                            </span>
                            <span class="drp_btn">
                                <i class="icofont-rounded-down"></i>
                            </span>
                            <div class="sub_menu">
                                <ul>
                                    <li>
                                        <router-link to="/info">
                                            {{ $t('links.welcome') }}
                                        </router-link>
                                    </li>
                                    <li>
                                        <a href="#">
                                            {{ $t('links.first-aid') }}
                                        </a>
                                    </li>
                                    <li>
                                        <router-link to="/blog">
                                            {{ $t('links.blog') }}
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/faq">
                                            {{ $t('links.faq') }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li class="nav-item">
                            <router-link class="nav-link" to="/contact">
                                {{ $t('links.contact') }}
                            </router-link>
                        </li>

                        <li class="nav-item has_dropdown">
                            <span class="nav-link">
                                <i class="icofont-download"></i>
                            </span>
                            <div class="sub_menu">
                                <ul>
                                    <li>
                                        <a href="/src/assets/downloads/kk001.pdf" target="_blank">
                                            {{ $t('links.download.application-form-kk001') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/src/assets/downloads/kk002.pdf" target="_blank">
                                            {{ $t('links.download.declaration-of-consent-kk002') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/src/assets/downloads/kk051.pdf" target="_blank">
                                            {{ $t('links.download.accompanying-form-kk051') }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li class="nav-item has_dropdown">
                            <span class="nav-link">
                                {{ $i18n.locale.toUpperCase() }} <i class="icofont-globe"></i>
                            </span>
                            <div class="sub_menu">
                                <ul>
                                    <template v-for="locale in languages">
                                        <li v-if="locale.activated">
                                            <a @click.prevent="setLocale(locale.code)">
                                                {{ locale.name }}
                                            </a>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link dark_btn" @click="openSignupDialog">
                                {{ $t('cta.sign-up') }}
                                <i class="icofont-arrow-right"></i>
                            </a>
                        </li>

                    </ul>

                    <SignupModal/>

                </div>
            </nav>
        </div>
    </header>

</template>
<!--eslint-disable-->
<script>

    import { mapActions, mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import SignupModal  from "~/_shared/SignupModalComponent.vue";

    // 'logoes' is an alias for 'src/assets/logoes'
    import companyLogo  from 'logoes/logo_with_black_text_and_background.png'

    export default {
        name: "Navigation",
        components: {

            SignupModal

        },
        created() {

            // ...

        },
        mounted() {

            window.addEventListener("scroll", this.handleScroll);

        },
        data() {

            return {

                sticky: false,

                companyLogo

            };

        },
        computed: {

            ...mapGetters({
                languages: 'locales/getLocales',
                token: 'auth/authenticated',
                isShowing: 'signupModal/visibility',
                isLoading: 'loading/status',
                courses: 'courses/getCourses'
            }),

        },
        methods: {

            ...mapActions({
                show: 'signupModal/setState',
                loading: 'loading/setLoading'
            }),

            setLocale(locale) {

                this.$i18n.locale = locale
            },

            openSignupDialog() {

                this.show(true)

            },

            handleScroll() {

                const currentScrollPosition = window.scrollY

                this.sticky = currentScrollPosition !== 0;

            },

            goToTop() {

                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    .header-border {

        border-top: 4px solid $accent;
    }

</style>