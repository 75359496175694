/* eslint-disable */

const mode          = process.env.VUE_APP_MODE
const version= process.env.PACKAGE_VERSION || '0'

function loadConfigs() {

    let scheme      = {}

    let api         = {}
    let firebase    = {}

    switch (mode) {

        case "development":

            api         = require("@/config/development/api.json")
            firebase    = require("@/config/development/firebase.json")
    
            scheme = {
                app: {
                    name: "tune-trafikskole",
                    description: "Very Cool dashboard app to monitor your eco-system",
                    version: version,
                    mode: mode,
                    devtools: true,
                    productionTip: false
                },
                api: api,
                firebase: firebase
            }

            return scheme

        case "production":

            api         = require("@/config/production/api.json")
            firebase    = require("@/config/production/firebase.json")
            
            scheme = {
                app: {
                    name: "tune-trafikskole",
                    description: "Very Cool dashboard app to monitor your eco-system",
                    version: version,
                    mode: mode,
                    devtools: true,
                    productionTip: false
                },
                api: api,
                firebase: firebase
            }

            return scheme

        default:

            return scheme
    }
}

export default loadConfigs()
