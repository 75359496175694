<!--eslint-disable-->
<template>

    <section class="need_section">
        <div class="need_section_inner">
            <div class="container">
                <div class="need_block">
                    <div class="need_text">
                        <div class="section_title">
                            <h2>
                                {{ $t('cta.need-help') }}
                            </h2>
                            <p>
                                <i class="icofont-clock-time"></i> Man - Fre: 10:00 til 20:00
                            </p>
                        </div>
                    </div>
                    <div class="need_action">
                        <template v-if="company.contact.phone">
                            <a :href="'tel:' + company.contact.phone" class="btn">
                                <i class="icofont-phone-circle"></i> {{ company.contact.phone }}
                            </a>
                            <router-link class="faq_btn" to="/info">
                                {{ $t('cta.need-help-secondary') }}
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapGetters } from "vuex";

    export default {
        name: "NeedHelp",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {
            return {

                // ...

            }
        },
        computed: {

            ...mapGetters({
                company: 'drivingSchool/getDrivingSchool'
            })

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>