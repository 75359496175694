<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            {{ $t('breadcrumb.about-us.title') }}
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('breadcrumb.start') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/about">
                                    {{ $t('breadcrumb.about-us.target') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section class="review_section">
            <div class="container">
                <div class="row">

                    <!-- Rating box -->
                    <rating v-for="(rating, index) in ratings" :rating="rating" :key="index"/>

                </div>
            </div>
        </section>

        <section class="about_us_page_section">
            <div class="dotes_anim_bloack">
                <div class="dots dotes_1"></div>
                <div class="dots dotes_2"></div>
                <div class="dots dotes_3"></div>
                <div class="dots dotes_4"></div>
                <div class="dots dotes_5"></div>
                <div class="dots dotes_6"></div>
                <div class="dots dotes_7"></div>
                <div class="dots dotes_8"></div>
            </div>
            <div class="container">
                <div class="about_block">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="section_title" data-aos="fade-in" data-aos-duration="1500">
                                <h2>
                                    {{ $t('views.about-us.about-block.heading') }}
                                </h2>
                                <p>
                                    {{ $t('views.about-us.about-block.paragraph-1') }}
                                </p>
                                <p>
                                    {{ $t('views.about-us.about-block.paragraph-2') }}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="abt_img_block" data-aos="fade-in" data-aos-duration="1500">
                                <a data-aos="fade-up" data-aos-duration="1500" class="popup-youtube play-button" data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1" data-toggle="modal" data-target="#myModal" title="XJj2PbenIsU">
                                    <div class="play_btn">
                                        <img :src="playIcon" alt="image">
                                        <div class="waves-block">
                                            <div class="waves wave-1"></div>
                                            <div class="waves wave-2"></div>
                                            <div class="waves wave-3"></div>
                                        </div>
                                    </div>
                                </a>
                                <div class="top_img">
                                    <img :src="about_01" alt="image">
                                    <img :src="about_02" alt="image">
                                </div>
                                <div class="bottom_img">
                                    <img :src="about_03" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="counter">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter_outer" data-aos="fade-up" data-aos-duration="1500">
                                <div class="counter_box">
                                    <p>
                                        <span class="counter-value" data-count="450">0</span><span>+</span>
                                    </p>
                                    <p>
                                        {{ $t('components.stats.stat-1.sub-title') }}
                                        <br>(data på vej...)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter_outer" data-aos="fade-up" data-aos-duration="1500">
                                <div class="counter_box">
                                    <p>
                                        <span class="counter-value" data-count="120">0</span><span>+</span>
                                    </p>
                                    <p>
                                        {{ $t('components.stats.stat-2.sub-title') }}
                                        <br>(data på vej...)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter_outer" data-aos="fade-up" data-aos-duration="1500">
                                <div class="counter_box">
                                    <p>
                                        <span class="counter-value" data-count="135">0</span><span>+</span>
                                    </p>
                                    <p>
                                        {{ $t('components.stats.stat-3.sub-title') }}
                                        <br>(data på vej...)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter_outer" data-aos="fade-up" data-aos-duration="1500">
                                <div class="counter_box">
                                    <p>
                                        <span class="counter-value" data-count="324">0</span><span>+</span>
                                    </p>
                                    <p>
                                        {{ $t('components.stats.stat-4.sub-title') }}
                                        <br>(data på vej...)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="row_am solution_page_section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="solution_image" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                            <img :src="solution_10" alt="image">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="solution_text" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                            <div class="section_title">
                                <h2>
                                    {{ $t('views.about-us.service-block.heading') }}
                                </h2>
                                <p>
                                    {{ $t('views.about-us.service-block.sub-title') }}
                                </p>
                            </div>
                            <ul>
                                <li class="ft_1" data-aos="fade-up" data-aos-duration="2000">
                                    <h3>
                                        {{ $t('views.about-us.service-block.item-1.heading') }}
                                    </h3>
                                    <p>
                                        {{ $t('views.about-us.service-block.item-1.sub-title') }}
                                    </p>
                                </li>
                                <li class="ft_2" data-aos="fade-up" data-aos-duration="2000">
                                    <h3>
                                        {{ $t('views.about-us.service-block.item-2.heading') }}
                                    </h3>
                                    <p>
                                        {{ $t('views.about-us.service-block.item-2.sub-title') }}
                                    </p>
                                </li>
                                <li class="ft_3" data-aos="fade-up" data-aos-duration="2000">
                                    <h3>
                                        {{ $t('views.about-us.service-block.item-3.heading') }}
                                    </h3>
                                    <p>
                                        {{ $t('views.about-us.service-block.item-3.sub-title') }}
                                    </p>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Team/>
        <Testimonials/>
        <BusinessPartners/>

    </div>

</template>
<!--eslint-disable-->
<script>

import { mapActions, mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Navigation       from "~/NavigationComponent";
    import Rating           from "~/about-us/RatingComponent";
    import Team             from "~/about-us/TeamComponent";
    import Testimonials     from "~/_shared/TestimonialComponent";
    import BusinessPartners from "~/_shared/BusinessPartnerComponent";

    // 'icons' is an alias for 'src/assets/icons'
    import playIcon         from 'icons/ic_play_video.svg'
    import solution_01      from 'icons/ic_solution_01.svg'
    import solution_02      from 'icons/ic_solution_02.svg'
    import solution_03      from 'icons/ic_solution_03.svg'

    // 'images' is an alias for 'src/assets/images'
    import about_01         from 'images/img_about_01.png'
    import about_02         from 'images/img_about_02.png'
    import about_03         from 'images/img_about_03.png'
    import solution_10      from 'images/img_solution_10.jpg'

    export default {
        name: "About",
        head: {

            title: 'Skal vi lære hinanden bedre at kende❓ Læs her om hvem vi er... 🙇‍♂️',
            meta: [
                {
                    name: 'description',
                    content: 'ebebebeb',
                },
            ],

        },
        components: {

            Navigation,
            Rating,
            Team,
            Testimonials,
            BusinessPartners

        },
        created() {

            this.populateRatings(this.token)

        },
        mounted() {

            // ...

        },
        data() {

            return {

                playIcon,
                about_01,
                about_02,
                about_03,
                solution_01,
                solution_02,
                solution_03,
                solution_10,

                rating: {}

            };

        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                ratings: 'ratings/getRatings'
            })

        },
        methods: {

            ...mapActions({

                getRatings: 'ratings/fetchRatings'
            }),

            populateRatings(token) {

                this.getRatings(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Ratings state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching ratings (catch) failed")

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>