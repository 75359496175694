<!--eslint-disable-->
<template>

    <div>

        <div class="white_option pb-100">

            <Navigation/>

        </div>

        <section class="error_area pt-105 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div class="error_wrapper text-center">
                            <div class="error_thumb mb-65">
                                <img :src="background" alt="">
                            </div>
                            <div class="error_content">
                                <h3>
                                    {{ $t('views.error-404.heading') }}
                                </h3>
                                <p>
                                    {{ $t('views.error-404.sub-title') }}
                                </p>
                                <router-link to="/" class="btn btn_main">
                                    {{ $t('links.home') }}
                                    <i class="icofont-arrow-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>
<!-- eslint-disable -->
<script>

    // '~' is an alias for 'src/components'
    import Navigation   from "~/NavigationComponent";

    // 'images' is an alias for 'src/assets/images'
    import background from 'images/backgrounds/bg_error_404.png'

    export default {
        name: "NotFound",
        head: {

            title: "Så'for den da ❔☝️ Siden kan vist ikke findes!? 🫥",
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {
            return {

                background

            }
        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    }

</script>
<!-- eslint-disable -->
<style lang="scss" scoped>

.pb-100 {
    padding-bottom: 100px;
}

.pt-105 {
    padding-top: 105px;
}

.mb-65 {
    margin-bottom: 65px;
}

.error_content {
    padding: 0 65px;
}

.error_content h3 {
    font-size: 36px;
    color: #011224;
    margin-bottom: 10px;
}

.error_content p {
    color: #57556a;
    font-weight: 600;
    margin-bottom: 40px;
}

</style>