/* eslint-disable */

/* ========================================================================= */
/*	Essential jQuery Plugins
/* ========================================================================= */

// [O] -> jQuery
const jQuery = require('jquery');

// [O] -> bootstrap
const bootstrap = require('bootstrap/dist/js/bootstrap');

// [O] -> popper
const popper = require('@popperjs/core/dist/cjs/popper');

/*
 *  'js' is an alias for /src/assets/js
 */

// [0] -> APP
const app = require('/src/assets/js/app');

const scripts = () => [

	jQuery,
	bootstrap,
	popper,
	app

]

export default scripts;
