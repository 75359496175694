/* eslint-disable */

import './registerServiceWorker'

/* importing core */
import { createApp }                from 'vue'
import { VueHeadMixin, createHead } from '@unhead/vue'
import axios                        from 'axios'

/* importing App */
import App                          from './App.vue'

/* importing Globals */
import globalComponents             from './globalComponents'
import globalDirectives             from './globalDirectives'
import globalFilters                from './globalFilters'

/* importing others */
import config                       from "§/configurator";
import i18n                         from "#/translator"
import plugins                      from "@/plugins/plugins";
import router                       from ">/router";
import scripts                      from "js/scripts";
import store                        from "*/store";

/* importing mixins */
import pageTitleMixin               from "mixins/page-title-mixin"

/* importing vendors */
import _                            from 'lodash';
import PrimeVue                     from 'primevue/config';
import ToastService                 from 'primevue/toastservice';

/* importing bootstrap */
import 'bootstrap/dist/js/bootstrap';

require("*/subscriber")

/**
 * Setting defaults for Axios
 */
axios.defaults.baseURL = config['api']['base_url']

/* initialising our App */
const app= createApp(App)

    /* initialising VueHead */
    const head = createHead()

    app.use(head)

    app.use(globalComponents)
    app.use(globalDirectives)

    app.config.globalProperties.$filter = globalFilters

    app.use(i18n)
    app.use(plugins)
    app.use(router)
    app.use(scripts)
    app.use(store)

    app.use(_)
    app.use(PrimeVue);
    app.use(ToastService);

    app.mixin(VueHeadMixin)
    app.mixin(pageTitleMixin)

    app.mount('#app')