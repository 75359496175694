<!--eslint-disable-->
<template>

    <router-view v-slot="{ Component, route }">
        <transition name="fade">
            <component :is="Component" :key="route.path" />
        </transition>
    </router-view>

</template>
<!--eslint-disable-->
<script>

    export default {};

</script>
<!--eslint-disable-->
<style lang="scss">

    // ...

</style>
