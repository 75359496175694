/* eslint-disable */
import axios from "axios"

const ERROR_MESSAGE = 'ERROR_MESSAGE'

export default {

    namespaced: true,

    state : {

        ratings: [],
        error: {}
    },

    actions : {

        async fetchRatings({ commit }, token) {

            let response = await axios

                .get('/get/ratings', {

                    headers: {
                        Authorization: "Bearer " + token
                    }
                })

                .then(function (response) {

                    commit('saveRatings', response.data)
                    commit(ERROR_MESSAGE, null)

                })

                .catch(function (error) {

                    commit("saveRatings", null)

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },
    },

    getters : {

        getRatings(state) {

            return state.ratings
        },

        error(state) {

            return state.error
        }
    },

    mutations : {

        saveRatings(state, payload) {
            state.ratings = payload
        },

        [ERROR_MESSAGE]: function (state, payload) {
            state.error = payload
        }
    }
}
