<!--eslint-disable-->
<template>

    <!-- Page-wrapper-Start -->
    <div class="page_wrapper">

        <Layout/>
        <NeedHelp/>
        <Footer/>

    </div>

</template>
<!--eslint-disable-->
<script>

    import AOS from 'aos';

    // '~' is an alias for 'src/components'
    import Layout   from "./Layout";
    import NeedHelp from "~/NeedHelpComponent";
    import Footer   from "~/FooterComponent";

    export default {

        name: "Airport",
        components: {

            Layout,
            NeedHelp,
            Footer

        },
        created() {

            // initialising Animate-On-Scroll
            AOS.init()

        },
        mounted() {

            // ...

        },
        data() {

            return {

                //...
            };

        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>