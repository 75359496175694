<!--eslint-disable-->
<template>

    <div class="top_home_wraper white_option">

        <Navigation/>

        <section class="banner_section">

        <!-- animated dots -->
        <div class="dotes_anim_bloack">
            <div class="dots dotes_1"></div>
            <div class="dots dotes_2"></div>
            <div class="dots dotes_3"></div>
            <div class="dots dotes_4"></div>
            <div class="dots dotes_5"></div>
            <div class="dots dotes_6"></div>
            <div class="dots dotes_7"></div>
            <div class="dots dotes_8"></div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="banner_text">

                        <div class="ban_inner_text" data-aos="fade-up" data-aos-duration="1500">
                            <span>
                                {{ $t('views.home.banner.span') }}
                            </span>
                            <h1>
                                {{ $t('views.home.banner.heading') }}
                            </h1>
                            <h2>
                                {{ $t('views.home.banner.sub-title') }}
                            </h2>
                            <p>
                                {{ $t('views.home.banner.paragraph') }}
                            </p>
                        </div>

                        <div class="btn_group">
                            <router-link class="btn btn_main" to="/course/start" data-aos="fade-right" data-aos-duration="1500">
                                {{ $t('buttons.course-start') }} <i class="icofont-arrow-right"></i>
                            </router-link>
                            <router-link class="btn btn_main" data-aos="fade-left" data-aos-duration="1500" to="/contact">
                                {{ $t('buttons.contact') }} <i class="icofont-arrow-right"></i>
                            </router-link>
                        </div>

                        <div class="offers" data-aos="fade-up" data-aos-duration="1500">
                            <span>
                                <i class="icofont-check-circled"></i> {{ $t('views.home.banner.call-to-action.item-left') }}
                            </span>
                            <span>
                                <i class="icofont-check-circled"></i> {{ $t('views.home.banner.call-to-action.item-right') }}
                            </span>
                        </div>

                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="banner_image" data-aos="fade-up" data-aos-duration="1500">
                        <img :src="heroImage" alt="image">
<!--                        <div class="image_blob" :style="{ backgroundImage: `url(${heroImage})` }"/>-->
                    </div>
                </div>
            </div>

        </div>
    </section>

    </div>

</template>
<!--eslint-disable-->
<script>

    // 'images' is an alias for 'src/assets/images'
    //import heroImage   from 'images/img_hero_image.png'
    import heroImage    from 'images/img_hero_banner.png'

    // '~' is an alias for 'src/components'
    import Navigation   from "~/NavigationComponent";

    export default {
        name: "Banner",
        components: {

            Navigation

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {

            return {

                heroImage

            };

        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    .btn_group {

        margin-left: -15px;
    }

    .image_blob {

        //background-image: url("");
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center center;
        width: 860px;
        height: 535px;
        box-shadow: 0 20px 5px 5px rgba(0, 0, 0, 0.2);
        animation: animate 5s ease-in-out infinite;
        transition: all 1s ease-in-out;
    }
    
    @keyframes animate {

        0% {

            border-radius: 60% 40% 30% 70% / 60% 30% 70% 40% ;
        }

        50% {

            border-radius: 30% 60% 70% 40% / 50% 60% 30% 60% ;
        }

        100% {

            border-radius: 60% 40% 30% 70% / 60% 30% 70% 40% ;
        }
    }

</style>