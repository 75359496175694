<!--eslint-disable-->
<template>

    <div id="app">
        <template v-if="!preloadingComplete">
            <Preloader/>
        </template>
        <template v-else>
            <router-view/>
        </template>
    </div>

</template>
<!--eslint-disable-->
<script>

    // importing Vuex maps
    import { mapActions, mapGetters } from "vuex";

    // importing config
    import config from "§/configurator";

    // '~' is an alias for 'src/components'
    import Preloader   from "~/PreloaderComponent";
    import router from "@/router/router";

    export default {
        name: "App",
        head: {

            title: 'tune trafikskole',
            description: 'My awesome site'

        },
        components: {

            Preloader

        },
        created() {

            this.authenticate()

        },
        mounted() {

            this.setPreloadComplete(false)

        },
        data() {
            return {

                credentials: {
                    email: config['api']['user'],
                    password: config['api']['secret']
                }

            }
        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                preloadingComplete: 'loading/preloadComplete'
            })

        },
        methods: {

            ...mapActions({

                login:                  'auth/fetchAuthToken',
                getContent:             'content/fetchWebsiteContent',
                getDrivingSchool:       'drivingSchool/fetchOurDrivingSchool',
                getLocales:             'locales/fetchLocales',
                getTags:                'tags/fetchTags',
                setPreloadComplete:     'loading/setPreloading',
            }),

            authenticate: function () {

                this.login(this.credentials)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("API bot authenticated")

                        }

                        this.populateWebsiteContent(this.token)

                    })

                    .catch(() => {

                        console.log("API bot (catch) failed")

                    })
            },

            populateWebsiteContent(token) {

                this.getContent(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Website content state populated")

                        }

                        this.populateDrivingSchool(token)

                    })

                    .catch(() => {

                        console.log("Fetching website content (catch) failed")

                    })

            },

            populateDrivingSchool(token) {

                this.getDrivingSchool(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Driving school state populated")

                        }

                        this.populateLocales(this.token)

                    })

                    .catch(() => {

                        console.log("Fetching driving school (catch) failed")

                    })

            },

            populateLocales(token) {

                this.getLocales(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Locales state populated")

                        }

                        this.populateTags(this.token)

                    })

                    .catch(() => {

                        console.log("Fetching locales (catch) failed")

                    })

            },

            populateTags(token) {

                this.getTags(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Tags state populated")

                        }

                        this.dataFetchingComplete(this.token)

                    })

                    .catch(() => {

                        console.log("Fetching tags (catch) failed")

                    })

            },

            dataFetchingComplete(status) {

                this.setPreloadComplete(status)

                    .then(() => {

                        console.log("Switching view to Home")

                        //router.push({ path: '/home' }) // TODO - det her duer ikke, da det breaker router.history

                    })

                    .catch(() => {

                        console.log("Fetching business-partners (catch) failed")

                    })

            }
        }
    };

</script>
<!--eslint-disable-->
<style lang="scss">

    // ...

</style>
