<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            {{ $t('breadcrumb.privacy-policy.title') }}
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('breadcrumb.start') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/privacy-policy">
                                    {{ $t('breadcrumb.privacy-policy.target') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section class="blog_detail_section" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
            <div class="container">
                <div class="blog_inner_pannel">

                    <!-- // -->
                    <div class="blog_info" data-aos="fade-up" data-aos-duration="2000">
                        <span class="date">
                            {{ $t('views.privacy-policy.published-at-date') + policy.created_at }}
                        </span>
                        <span class="revision">
                            {{ $t('views.privacy-policy.revision') + policy.id }}
                        </span>
                        <div class="authore_block" data-aos="fade-up" data-aos-duration="1000">
                            <div class="authore">
                                <div class="img avatar">
                                    <img class="avatar" :src="policy.written_by.img_avatar" alt="image">
                                </div>
                                <div class="text">
                                    <h4>
                                        {{ policy.written_by.first_name + " " + policy.written_by.last_name }}
                                    </h4>
                                    <template v-for="meta in policy.written_by.role.meta">
                                        <span v-if="meta.i18n === $i18n.locale">
                                            {{ meta.name }}
                                        </span>
                                    </template>
                                </div>
                            </div>
<!--                            <div class="blog_tag">-->
<!--                                <span>-->
<!--                                    Software-->
<!--                                </span>-->
<!--                            </div>-->
                        </div>
                    </div>

                    <!-- TERMS CONTENT -->
                    <div class="info pt-50" data-aos="fade-up" data-aos-duration="1500">
                        <template v-for="meta in policy.meta">
                            <div v-if="meta.i18n === $i18n.locale">
                                <span v-html="meta.content"/>
                            </div>
                        </template>
                    </div>

                    <!-- SHARE THIS -->
                    <div class="blog_authore" data-aos="fade-up" data-aos-duration="1500">
                        <div class="social_media">
                            <h3>
                                {{ $t('views.privacy-policy.share-this') }}
                            </h3>
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="icofont-print"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-share"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    </div>

</template>
<!--eslint-disable-->
<script>

import { mapActions, mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Navigation   from "~/NavigationComponent";

    export default {
        name: "PrivacyPolicy",
        head: {

            title: 'Det er en god idé at læse betingelserne inden du starter 🤖',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation

        },
        created() {

            this.populatePrivacyPolicy(this.token)

        },
        mounted() {

            // ...

        },
        data() {

            return {

                // ...

            };

        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                policy: 'privacyPolicy/getPolicy'
            }),

        },
        methods: {

            ...mapActions({

                getPrivacyPolicy: 'privacyPolicy/fetchPrivacyPolicy'
            }),

            populatePrivacyPolicy(token) {

                this.getPrivacyPolicy(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Privacy-Policy state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching privacy-policy (catch) failed")

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    .container {

        max-width: 1200px
    }

    .avatar {

        height: 50px;
    }

    .revision {

        @extend .date;

        display: flex;
        float: right
    }

</style>