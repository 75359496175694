/* eslint-disable */
import axios from "axios"

const ERROR_MESSAGE = 'ERROR_MESSAGE'

export default {

    namespaced: true,

    state : {
        
        stories: [],
        meta: {},
        error: {}
    },

    actions : {
        
        async fetchStories({ commit }, payload) {

            let response = await axios
                
                .get('/get/blogs', {
                    
                    headers: {
                        Authorization: "Bearer " + payload.token
                    },
                    params: {
                        page: payload.page
                    }
                })
                
                .then(function (response) {
                    
                    commit('saveStories', response.data.blogs)
                    commit('saveMeta', response.data.meta.pagination)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {

                    commit("saveStories", null)
                    commit('saveMeta', null)

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        }
    },

    getters : {

        getStories(state) {

            return state.stories
        },

        getStoryDetails: (state) => (id) => {

            return state.stories.find(story => story.id === id)
        },

        getMeta(state) {

            return state.meta
        },

        error(state) {

            return state.error
        }
    },

    mutations : {

        saveStories(state, payload) {
            state.stories = payload
        },

        saveMeta(state, payload) {
            state.meta = payload
        },

        [ERROR_MESSAGE]: function (state, payload) {
            state.error = payload
        }
    }
}
