<!--eslint-disable-->
<template>

    <div class="card" data-aos="fade-up" data-aos-duration="1500">
        <template v-for="meta in help.meta">
            <div v-if="meta.i18n === $i18n.locale" class="blog_info">

                <div class="card-header" :id="help.id">
                    <h2 class="mb-0">
                        <button type="button" class="btn btn-link" :class="{ 'active' : help.id === 1 }" data-toggle="collapse" :data-target="'#collapse' + help.id">
                            <i class="icon_faq icofont-plus"></i> {{ meta.question }}
                        </button>
                    </h2>
                </div>
                <div :id="'collapse' + help.id" class="collapse" :class="{ 'show' : help.id === 1 }" :aria-labelledby="help.id" data-parent="#accordionExample">
                    <div class="card-body">
                        <p>
                            {{ meta.answer }}
                        </p>
                    </div>
                </div>

            </div>
        </template>
    </div>

</template>
<!--eslint-disable-->
<script>

    export default {
        name: "QuestionComponent",
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        props: [ 'help' ],
        data() {
            return {

                // ...

            };
        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>