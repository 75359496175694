<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            Nyttig info
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('links.home') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <a href="contact-us.html">
                                    Velkommen
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>



    </div>

</template>
<!--eslint-disable-->
<script>

    // '~' is an alias for 'src/components'
    import Navigation   from "~/NavigationComponent";

    export default {
        name: "Info",
        head: {

            title: 'Velkommen til tune trafikskole 👋🚗',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation

        },
        created() {

            // ...
            alert("Obs! Denne side er under udvikling ☝️")

        },
        mounted() {

            // ...

        },
        data() {

            return {

                // ...

            };

        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>