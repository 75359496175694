<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            {{ $t('breadcrumb.faq.title') }}
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('breadcrumb.start') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/faq">
                                    {{ $t('breadcrumb.faq.target') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section id="faqBlock" class="row_am faq_section">
            <div class="container">
                <div class="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                    <h2>
                        <span>{{ $t('views.faq.title-short') }}</span> - {{ $t('views.faq.title') }}
                    </h2>
                    <p v-html="$t('views.faq.sub-title')"/>
                </div>
                <div class="faq_panel">
                    <div class="accordion" id="accordionExample">

                        <help v-for="(help, index) in questions" :help="help" :key="index"/>

                    </div>
                </div>
            </div>
        </section>

    </div>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapActions, mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Navigation   from "~/NavigationComponent";
    import Help         from "~/faq/QuestionComponent";

    // 'images' is an alias for 'src/assets/images'
    import paperPlane   from 'images/overlays/overlay_paper_plane.png'

    // 'icons' is an alias for 'src/assets/icons'
    import mail         from 'icons/ic_contact_mail.png'
    import location     from 'icons/ic_contact_location.png'
    import phone        from 'icons/ic_contact_phone.png'

    export default {
        name: "FAQ",
        head: {

            title: 'Er der noget du er i tvivl om? 🤔 Find svaret herunder...',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation,
            Help

        },
        created() {

            this.populateFaqs(this.token)

        },
        mounted() {

            // ...

        },
        data() {

            return {

                paperPlane,
                mail,
                location,
                phone,

                Help: {}

            };

        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                questions: 'faqs/getQuestions'
            }),

        },
        methods: {

            ...mapActions({

                getFaqs: 'faqs/fetchQuestions'
            }),

            populateFaqs(token) {

                this.getFaqs(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("FAQ's state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching FAQ's (catch) failed")

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>