<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            {{ $t('breadcrumb.course-details.title') }}
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('breadcrumb.start') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/course/start">
                                    {{ $t('breadcrumb.course-details.intermediate') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link :to="{ name: 'CourseDetails', params: { uid: this.uid }}">
                                    {{ "#" + this.uid }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section class="service_detail_section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <!-- Service Left Side -->
                        <div class="service_left_side">
                            <template v-for="meta in course(this.uid).meta">
                                <div v-if="meta.i18n === $i18n.locale" class="section_title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>
                                        {{ meta.description ? meta.description : '' }}
                                    </h2>
                                    <p>
                                        {{ meta.paragraph ? meta.paragraph : '' }}
                                    </p>
                                </div>
                            </template>
                            <div class="img" data-aos="fade-up" data-aos-duration="2000">
                                <img src="https://fleetcomplete.dk/wp-content/uploads/sites/36/2018/08/helping-fleets-thrive-cta.jpg" alt="image">
                            </div>
                            <template v-for="meta in course(this.uid).meta">
                                <template v-if="meta.i18n === $i18n.locale">
                                    <template v-if="meta.sub_heading_1">
                                        <h3 data-aos="fade-up" data-aos-duration="1500">
                                            {{ meta.sub_heading_1 ? meta.sub_heading_1 : '' }}
                                        </h3>
                                    </template>
                                    <template v-if="meta.sub_paragraph_1">
                                        <p data-aos="fade-up" data-aos-duration="1500">
                                            {{ meta.sub_paragraph_1 ? meta.sub_paragraph_1 : '' }}
                                        </p>
                                    </template>
                                    <ul class="list_block" data-aos="fade-up" data-aos-duration="1500">
                                        <li v-if="meta.content_title_1">
                                            <h3>
                                                {{ meta.content_title_1 }}
                                            </h3>
                                            <p>
                                                {{ meta.content_text_1 ? meta.content_text_1 : '' }}
                                            </p>
                                        </li>
                                        <li v-if="meta.content_title_2">
                                            <h3>
                                                {{ meta.content_title_2 }}
                                            </h3>
                                            <p>
                                                {{ meta.content_text_2 ? meta.content_text_2 : '' }}
                                            </p>
                                        </li>
                                        <li v-if="meta.content_title_3">
                                            <h3>
                                                {{ meta.content_title_3 }}
                                            </h3>
                                            <p>
                                                {{ meta.content_text_3 ? meta.content_text_3 : '' }}
                                            </p>
                                        </li>
                                        <li v-if="meta.content_title_4">
                                            <h3>
                                                {{ meta.content_title_4 }}
                                            </h3>
                                            <p>
                                                {{ meta.content_text_4 ? meta.content_text_4 : '' }}
                                            </p>
                                        </li>
                                        <li v-if="meta.content_title_5">
                                            <h3>
                                                {{ meta.content_title_5 }}
                                            </h3>
                                            <p>
                                                {{ meta.content_text_5 ? meta.content_text_5 : '' }}
                                            </p>
                                        </li>
                                        <li v-if="meta.content_title_6">
                                            <h3>
                                                {{ meta.content_title_6 }}
                                            </h3>
                                            <p>
                                                {{ meta.content_text_6 ? meta.content_text_6 : '' }}
                                            </p>
                                        </li>
                                    </ul>
                                    <template v-if="meta.sub_heading_2">
                                        <h3 data-aos="fade-up" data-aos-duration="1500">
                                            {{ meta.sub_heading_2 ? meta.sub_heading_2 : '' }}
                                        </h3>
                                    </template>
                                    <template v-if="meta.sub_paragraph_2">
                                        <p data-aos="fade-up" data-aos-duration="1500">
                                            {{ meta.sub_paragraph_2 ? meta.sub_paragraph_2 : '' }}
                                        </p>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </div>

                    <div class="col-lg-4">

                        <!-- Course Content -->
                        <div class="service_right_side">
                            <div class="service_list_panel" data-aos="fade-up" data-aos-duration="1500">
                                <h3>
                                    {{ $t('views.course-details.content.label') }}
                                </h3>
                                <ul class="service_list">
                                    <template v-for="meta in course(this.uid).meta">
                                        <template v-if="meta.i18n === $i18n.locale">
                                            <li v-if="meta.content_title_1">
                                                <a href="#" class="active">
                                                    {{ meta.content_title_1 }}
                                                </a>
                                            </li>
                                            <li v-if="meta.content_title_2">
                                                <a href="#">
                                                    {{ meta.content_title_2 }}
                                                </a>
                                            </li>
                                            <li v-if="meta.content_title_3">
                                                <a href="#">
                                                    {{ meta.content_title_3 }}
                                                </a>
                                            </li>
                                            <li v-if="meta.content_title_4">
                                                <a href="#">
                                                    {{ meta.content_title_4 }}
                                                </a>
                                            </li>
                                            <li v-if="meta.content_title_5">
                                                <a href="#">
                                                    {{ meta.content_title_5 }}
                                                </a>
                                            </li>
                                            <li v-if="meta.content_title_6">
                                                <a href="#">
                                                    {{ meta.content_title_6 }}
                                                </a>
                                            </li>
                                        </template>
                                    </template>
                                </ul>
                            </div>

                            <!-- Course Sign Up -->
                            <div class="side_contact_block" data-aos="fade-up" data-aos-duration="1500">
                                <div class="icon">
                                    <i class="icofont-bulb-alt"></i>
                                </div>
                                <h3>
                                    {{ $t('views.course-details.enroll.label') }}
                                </h3>
                                <a class="btn btn_main" @click="openSignupDialog">
                                    {{ $t('buttons.enroll') }} <i class="icofont-arrow-right"></i>
                                </a>
                                <p>
                                    <a :href="'tel:' + company.contact.phone">
                                        <i class="icofont-phone-circle"></i> {{ company.contact.phone }}
                                    </a>
                                </p>
                            </div>

                            <!-- // -->
<!--                            <div class="action_btn">-->
<!--                                <a href="images/pdf-brochure.pdf" target="blank" class="btn" data-aos="fade-up" data-aos-duration="1500">-->
<!--                                    <span>-->
<!--                                        <i class="icofont-file-pdf"></i>-->
<!--                                    </span>-->
<!--                                    <p>-->
<!--                                        Company Profile-->
<!--                                    </p>-->
<!--                                </a>-->
<!--                                <a href="images/pdf-brochure.pdf" target="blank" class="btn" data-aos="fade-up" data-aos-duration="1500">-->
<!--                                    <span>-->
<!--                                        <i class="icofont-file-powerpoint"></i>-->
<!--                                    </span>-->
<!--                                    <p>-->
<!--                                        Download PPT-->
<!--                                    </p>-->
<!--                                </a>-->
<!--                            </div>-->

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapActions, mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Navigation       from "~/NavigationComponent";

    export default {
        name: "CourseDetails",
        head: {

            title: 'Klar til at tage kørekort? 🚗 Se hvornår næste hold starter?',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {

            return {

                uid: this.$route.params.uid

            };

        },
        computed: {

            ...mapGetters({
                course: 'courses/getCourseDetails',
                company: 'drivingSchool/getDrivingSchool'
            })

        },
        methods: {

            ...mapActions({
                show: 'signupModal/setState'
            }),

            openSignupDialog() {

                this.show(true)

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>