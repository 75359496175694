<!--eslint-disable-->
<template>

    <div class="col-lg-4">
        <div class="review_box" data-aos="fade-up" data-aos-duration="2000">
            <div class="icon">
                <img :src="rating.logo_path" class="brand_image" alt="image">
            </div>
            <div class="rating">
                <span><i class="icofont-star"></i></span>
                <span><i class="icofont-star"></i></span>
                <span><i class="icofont-star"></i></span>
                <span><i class="icofont-star"></i></span>
                <span><i class="icofont-star"></i></span>
            </div>
            <div class="text">
                <h3>
                    <span class="main_rating">?</span> {{ $t('views.about-us.rating-block.out-of') }} ? {{ $t('views.about-us.rating-block.based-on') }} <span class="total_review">? {{ $tc('views.about-us.rating-block.reviews', 2) }}</span>
                </h3>
            </div>
        </div>
    </div>

</template>
<!--eslint-disable-->
<script>

import { mapActions, mapGetters } from "vuex";

    export default {
        name: "RatingComponent",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        props: [ 'rating' ],
        data() {

            return {

                // ...
            };

        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    .brand_image {

        height: 50px;
        margin-bottom: 10px;
    }

</style>