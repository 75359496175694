<!--eslint-disable-->
<template>

    <div class="pagination_block" data-aos="fade-up" data-aos-duration="1500">
        <div class="row">
            <div class="col-lg-8">
                <ul>

                    <li>
                        <a href="#" @click.prevent="switched(meta.current_page - 1)" class="prev" :class="{ 'disabled' : meta.current_page === 1 }">
                            <i class="icofont-double-left"></i>
                        </a>
                    </li>

                    <template v-if="currentSection > 1">
                        <li>
                            <a href="#" @click.prevent="switched(1)">
                                1
                            </a>
                        </li>
                        <li>
                            <a href="#" @click.prevent="goBackOneSection()">
                                ...
                            </a>
                        </li>
                    </template>


                    <li v-for="page in pages">
                        <a href="#" @click.prevent="switched(page)" :class="{ 'active' : meta.current_page === page }">
                            {{ page }}
                        </a>
                    </li>

                    <template v-if="currentSection < sections">
                        <li>
                            <a href="#" @click.prevent="goForwardOneSection()">
                                ...
                            </a>
                        </li>
                        <li>
                            <a href="#" @click.prevent="switched(meta.last_page)">
                                {{ meta.last_page }}
                            </a>
                        </li>
                    </template>

                    <li>
                        <a href="#" @click.prevent="switched(meta.current_page + 1)" class="next" :class="{ 'disabled' : meta.current_page === meta.last_page }">
                            <i class="icofont-double-right"></i>
                        </a>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</template>
<!--eslint-disable-->
<script>

    export default {

        name: "Paginator",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            if (this.meta.current_page > this.meta.last_page) {

                this.switched(this.meta.last_page)
            }

        },
        props: [ 'meta' ],
        data() {
            return {

                numbersPerSection: 3

            }
        },
        computed: {

            currentSection() {

                return Math.ceil(this.meta.current_page / this.numbersPerSection)
            },

            sections() {

                return Math.ceil(this.meta.last_page / this.numbersPerSection)
            },

            currentPage() {

                return (this.currentSection -1) * this.numbersPerSection + 1
            },

            lastPage() {

                let lastPage = ((this.currentSection -1) * this.numbersPerSection) + this.numbersPerSection

                if (this.currentSection === this.sections) {

                    lastPage = this.meta.last_page
                }

                return lastPage
            },

            pages() {

                return _.range(this.currentPage, this.lastPage + 1)
            }

        },
        methods: {

            switched(page) {

                if (this.pageSelectionExceeded(page)) {

                    return
                }

                this.$emit('pagination:switched', page)
            },

            pageSelectionExceeded(page) {

                return page <= 0 || page > this.meta.last_page
            },

            firstPageOfSection(currentSection) {

                return (currentSection - 1) * this.numbersPerSection + 1
            },

            goBackOneSection() {

                this.switched(this.firstPageOfSection(this.currentSection - 1))
            },

            goForwardOneSection() {

                this.switched(this.firstPageOfSection(this.currentSection + 1))
            }

        }

    }

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    .disabled {

        pointer-events: none;
        color: var(--gray-400) !important;
        border-color: var(--gray-400) !important;
    }

</style>