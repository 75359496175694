<!--eslint-disable-->
<template>

    <section class="row_am unique_section">
        <div class="container">
            <div class="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2>
                    {{ $t('components.unique-features.heading') }}
                </h2>
                <p>
                    {{ $t('components.unique-features.sub-title') }}
                </p>
            </div>
            <div class="features_inner" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">
                <!-- card -->
                <div class="feature_card">
                    <div class="icons">
                        <img :src="featureIcon1" alt="image">
                        <div class="dot_block">
                            <span class="dot_anim"></span>
                            <span class="dot_anim"></span>
                            <span class="dot_anim"></span>
                        </div>
                    </div>
                    <div class="inner_text">
                        <h3>
                            {{ $t('components.unique-features.feature-1.heading') }}
                        </h3>
                        <p>
                            {{ $t('components.unique-features.feature-1.sub-title') }}
                        </p>
                        <router-link class="btn text_btn" to="/about">
                            {{ $t('buttons.read-more') }} <i class="icofont-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
                <!-- card -->
                <div class="feature_card">
                    <div class="icons">
                        <img :src="featureIcon2" alt="image">
                        <div class="dot_block">
                            <span class="dot_anim"></span>
                            <span class="dot_anim"></span>
                            <span class="dot_anim"></span>
                        </div>
                    </div>
                    <div class="inner_text">
                        <h3>
                            {{ $t('components.unique-features.feature-2.heading') }}
                        </h3>
                        <p>
                            {{ $t('components.unique-features.feature-2.sub-title') }}
                        </p>
                        <router-link class="btn text_btn" to="/about">
                            {{ $t('buttons.read-more') }} <i class="icofont-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
                <!-- card -->
                <div class="feature_card">
                    <div class="icons">
                        <img :src="featureIcon3" alt="image">
                        <div class="dot_block">
                            <span class="dot_anim"></span>
                            <span class="dot_anim"></span>
                            <span class="dot_anim"></span>
                        </div>
                    </div>
                    <div class="inner_text">
                        <h3>
                            {{ $t('components.unique-features.feature-3.heading') }}
                        </h3>
                        <p>
                            {{ $t('components.unique-features.feature-3.sub-title') }}
                        </p>
                        <router-link class="btn text_btn" to="/about">
                            {{ $t('buttons.read-more') }} <i class="icofont-arrow-right"></i>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </section>

</template>
<!--eslint-disable-->
<script>

    // 'icons' is an alias for 'src/assets/icons'
    import featureIcon1 from 'icons/ic_feature_01.svg'
    import featureIcon2 from 'icons/ic_feature_02.svg'
    import featureIcon3 from 'icons/ic_feature_03.svg'

    export default {
        name: "UniqueFeatures",
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {
            return {

                featureIcon1,
                featureIcon2,
                featureIcon3

            }
        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>