/* eslint-disable */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// @ is an alias for 'src'
import config from "@/config/configurator";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

        apiKey:             config['firebase']['apiKey'],
        authDomain:         config['firebase']['authDomain'],
        projectId:          config['firebase']['projectId'],
        storageBucket:      config['firebase']['storageBucket'],
        messagingSenderId:  config['firebase']['messagingSenderId'],
        appId:              config['firebase']['appId'],
        measurementId:      config['firebase']['measurementId']

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const performance = getPerformance(app);

const firebase = () => [

        app,
        analytics,
        performance
]

export default firebase;