<!--eslint-disable-->
<template>

    <div class="col-md-4">
        <div class="story_box" data-aos="fade-up" data-aos-duration="1500">
            <div class="story_img">
                <img :src="course.image ? course.image : placeholderImage" alt="image">
                <span>
                    <span>{{ $filter.short_date(course.starting_date) }}</span> {{ $filter.capitalize($filter.short_month(course.starting_date)) }}
                </span>
            </div>
            <div class="story_text">
                <div class="statstic">
                    <span class="mr-2">
                        <i class="icofont-users"></i> {{ course.max_seats }} pladser
                    </span>
                    <span class="mr-2">
                        <i class="icofont-clock-time"></i> {{ $filter.time(course.starting_date) }}
                    </span>
                    <span class="mr-2">
                        <i class="icofont-odnoklassniki"></i> {{ course.instructor.first_name + " " + course.instructor.last_name }}
                    </span>
                </div>
                <template v-for="meta in course.meta">
                    <h3 v-if="meta.i18n === $i18n.locale">
                        {{ meta.title }}
                    </h3>
                </template>
                <template v-for="meta in course.meta">
                    <p v-if="meta.i18n === $i18n.locale">
                        {{ meta.description }}
                    </p>
                </template>
                <router-link class="btn text_btn" :to="{ name: 'CourseDetails', params: { uid: course.uid }}" :course="course" :key="course.id">
                    {{ $t('buttons.read-more') }} <i class="icofont-arrow-right"></i>
                </router-link>
            </div>
        </div>
    </div>

</template>
<!--eslint-disable-->
<script>

    // 'images' is an alias for 'src/assets/images'
    import placeholderImage from 'images/img_story_02.png'

    export default {
        name: "FutureCourse",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        props: [ 'course' ],
        data() {

            return {

                placeholderImage

            };

        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>