/* eslint-disable */
import axios from "axios"

const VISIBILITY_STATE = 'VISIBILITY_STATE'

export default {

    namespaced: true,

    state : {

        visible: false,
    },

    actions : {

        /**
         * Action to set the visibility state
         *
         * @param commit
         * @param isShowing
         */
        setState ({ commit }, isShowing) {

            commit(VISIBILITY_STATE, isShowing)
        }
        
    },

    getters : {

        visibility(state) {

            return state.visible
        }
    },

    mutations : {

        [VISIBILITY_STATE]: function (state, isShowing) {

            state.visible = isShowing
        },
    }
}
