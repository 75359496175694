<!--eslint-disable-->
<template>

    <section class="row_am our_team_section">
        <div class="container">
            <div class="section_title" data-aos="fade-up" data-aos-duration="1500">
                <h2>
                    {{ $t('views.about-us.teams-block.heading') }}
                </h2>
                <p>
                    {{ $t('views.about-us.teams-block.sub-title') }}
                </p>
            </div>
            <div class="team_block">
                <div class="row">

                    <div v-for="employee in employees" class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
                        <div class="team_inner">
                            <div class="img">
                                <img :src="employee.img_avatar" alt="">
                                <div class="social_media">
                                    <template v-for="social in employee.sso">
                                        <a :href="social.name">
                                            <i class="icofont-facebook"></i>
                                        </a>
                                    </template>
                                </div>
                            </div>
                            <div class="text">
                                <h3>
                                    {{ employee.first_name + " " + employee.last_name }}
                                </h3>
                                <template v-for="role in employee.roles">
                                    <template v-for="meta in role.meta">
                                        <span v-if="meta.i18n === $i18n.locale">
                                            {{ meta.name }}
                                        </span>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</template>
<!--eslint-disable-->
<script>

import { mapActions, mapGetters } from "vuex";

    export default {
        name: "Team",
        components: {

            // ...

        },
        created() {

            this.populateEmployees(this.token)

        },
        mounted() {

            // ...

        },
        data() {

            return {

                // ...
            };

        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                employees: 'employees/getEmployees'
            })

        },
        methods: {

            ...mapActions({

                getEmployees: 'employees/fetchEmployees'
            }),

            populateEmployees(token) {

                this.getEmployees(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Employees state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching employees (catch) failed")

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>