<!--eslint-disable-->
<template>

    <section class="powerful_solution" data-aos="fade-in" data-aos-duration="1000">

        <!-- animated dots -->
        <div class="dotes_anim_bloack">
            <div class="dots dotes_1"></div>
            <div class="dots dotes_2"></div>
            <div class="dots dotes_3"></div>
            <div class="dots dotes_4"></div>
            <div class="dots dotes_5"></div>
            <div class="dots dotes_6"></div>
            <div class="dots dotes_7"></div>
            <div class="dots dotes_8"></div>
        </div>

        <div class="bg_pattern">
            <img :src="wavyLine" alt="image">
        </div>
        <div class="container">
            <div class="section_title" data-aos="fade-up" data-aos-duration="1000">
                <h2>
                    {{ $t('components.stats.heading') }}
                </h2>
                <p>
                    {{ $t('components.stats.sub-title') }}
                </p>
            </div>
            <div class="quality_lable" data-aos="fade-up" data-aos-duration="1000">
                <ul>
                    <li>
                        <p>
                            <i class="icofont-check-circled"></i>
                            {{ $t('components.stats.call-to-action.item-left') }}
                        </p>
                    </li>
                    <li>
                        <p>
                            <i class="icofont-check-circled"></i>
                            {{ $t('components.stats.call-to-action.item-middle') }}
                        </p>
                    </li>
                    <li>
                        <p>
                            <i class="icofont-check-circled"></i>
                            {{ $t('components.stats.call-to-action.item-right') }}
                        </p>
                    </li>
                </ul>
            </div>
            <div class="counters_block" data-aos="fade-up" data-aos-duration="1000">
                <ul class="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                    <li>
                        <div class="text">
                            <p>
                                <span id="happy-students" class="counter-value"/>
                            </p>
                            <p>
                                {{ $t('components.stats.stat-1.sub-title') }}
                                <br>(data på vej...)
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="text">
                            <p>
                                <span id="pass-rate" class="counter-value"/>
                            </p>
                            <p>
                                {{ $t('components.stats.stat-2.sub-title') }}
                                <br>(data på vej...)
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="text">
                            <p>
                                <span id="course-completed" class="counter-value"/>
                            </p>
                            <p>
                                {{ $t('components.stats.stat-3.sub-title') }}
                                <br>(data på vej...)
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="text">
                            <p>
                                <span id="online-reviews" class="counter-value"/>
                            </p>
                            <p>
                                {{ $t('components.stats.stat-4.sub-title') }}
                                <br>(data på vej...)
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="images_gallery_block row">
                <div class="gl_block col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
                    <div class="img">
                        <img :src="solution1" alt="image">
                    </div>
                    <div class="img">
                        <img :src="solution2" alt="image">
                    </div>
                </div>
                <div class="gl_block col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                    <div class="img">
                        <img :src="solution3" alt="image">
                    </div>
                    <div class="img">
                        <img :src="solution4" alt="image">
                    </div>
                    <div class="img">
                        <img :src="solution5" alt="image">
                    </div>
                </div>
                <div class="gl_block col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="150">
                    <div class="img">
                        <img :src="solution6" alt="image">
                    </div>
                    <div class="img">
                        <img :src="solution7" alt="image">
                    </div>
                </div>
                <div class="gl_block col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
                    <div class="img">
                        <img :src="solution8" alt="image">
                    </div>
                    <div class="img">
                        <img :src="solution9" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<!--eslint-disable-->
<script>

    import { CountUp } from "countup.js";

    // 'images' is an alias for 'src/assets/images'
    import wavyLine   from 'images/overlays/overlay_wavy_line.png'

    import solution1  from 'images/img_solution_01.png'
    import solution2  from 'images/img_solution_02.png'
    import solution3  from 'images/img_solution_03.png'
    import solution4  from 'images/img_solution_04.png'
    import solution5  from 'images/img_solution_05.png'
    import solution6  from 'images/img_solution_06.png'
    import solution7  from 'images/img_solution_07.png'
    import solution8  from 'images/img_solution_08.png'
    import solution9  from 'images/img_solution_09.png'

    export default {
        name: "Status",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            // ...
            const setup = {

                startVal: 0,
                decimalPlaces: 0,
                duration: 3,
                useGrouping: false,
                operators: {
                    "stat-1": this.$t('components.stats.stat-1.operator'),
                    "stat-2": this.$t('components.stats.stat-2.operator'),
                    "stat-3": this.$t('components.stats.stat-3.operator'),
                    "stat-4": this.$t('components.stats.stat-4.operator')
                }
            }

            // initialising "Happy Students" count
            const countHappyStudents = new CountUp('happy-students', 0, { ...setup, suffix: setup.operators["stat-1"] });

            if (!countHappyStudents.error) { countHappyStudents.start() } else { console.error(countHappyStudents.error) }

            // initialising "Pass-Rate" count
            const countPassRate = new CountUp('pass-rate', 0, { ...setup, suffix: setup.operators["stat-2"] });

            if (!countPassRate.error) { countPassRate.start() } else { console.error(countPassRate.error) }

            // initialising "Courses Completed" count
            const countCourseCompleted = new CountUp('course-completed', 0, { ...setup, suffix: setup.operators["stat-3"] });

            if (!countCourseCompleted.error) { countCourseCompleted.start() } else { console.error(countCourseCompleted.error) }

            // initialising "Online Reviews" count
            const countOnlineReviews = new CountUp('online-reviews', 0, { ...setup, suffix: setup.operators["stat-4"] });

            if (!countOnlineReviews.error) { countOnlineReviews.start() } else { console.error(countOnlineReviews.error) }

        },
        data() {
            return {

                wavyLine,
                solution1,
                solution2,
                solution3,
                solution4,
                solution5,
                solution6,
                solution7,
                solution8,
                solution9

            }
        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>