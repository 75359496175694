<!--eslint-disable-->
<template>

    <footer>
        <div class="top_footer" id="contact">

            <!-- animated dots -->
            <div class="dotes_anim_bloack">
                <div class="dots dotes_1"></div>
                <div class="dots dotes_2"></div>
                <div class="dots dotes_3"></div>
                <div class="dots dotes_4"></div>
                <div class="dots dotes_5"></div>
                <div class="dots dotes_6"></div>
                <div class="dots dotes_7"></div>
                <div class="dots dotes_8"></div>
            </div>

            <!-- container start -->
            <div class="container">
                <!-- row start -->
                <div class="row">

                    <!-- footer link 1 -->
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="abt_side">
                            <div class="logo">
                                <img :src="companyLogo" alt="image">
                            </div>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and type setting industry ype setting industry lorem Ipsum has been the industrys standard dummy.
                            </p>
                            <div class="news_letter_block">
                                <form action="submit">
                                    <div class="form-group">
                                        <input type="email" :placeholder="$t('placeholders.subscribe-to-newsletter')" class="form-control">
                                        <button class="btn"><i class="icofont-paper-plane"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- footer link 2 -->
                    <div class="col-lg-2 col-md-6 col-12">
                        <div class="links">
                            <h3>
                                {{ $t('links.titles.useful-links') }}
                            </h3>
                            <ul>
                                <li>
                                    <a href="/">
                                        {{ $t('links.home') }}
                                    </a>
                                </li>
                                <li>
                                    <router-link to="/course/start">
                                        {{ $t('links.course-start') }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/prices">
                                        {{ $t('links.prices') }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/about">
                                        {{ $t('links.about') }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/blog">
                                        {{ $t('links.blog') }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- footer link 3 -->
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="links">
                            <h3>
                                {{ $t('links.titles.help-and-support') }}
                            </h3>
                            <ul>
                                <li>
                                    <router-link to="/contact">
                                        {{ $t('links.contact') }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/faq">
                                        {{ $t('links.faq') }}
                                    </router-link>
                                </li>
                                <li>
                                    <a href="#">
                                        ...
                                    </a>
                                </li>
                                <li>
                                    <router-link to="/business-terms">
                                        {{ $t('links.business-terms') }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy-policy">
                                        {{ $t('links.privacy-policy') }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- footer link 4 -->
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="try_out">
                            <h3>
                                {{ $t('links.titles.contact-us') }}
                            </h3>
                            <ul>
                                <li>
                                    <span class="icon">
                                        <img :src="contact1" alt="image">
                                    </span>
                                    <div class="text">
                                        <template v-for="location in company.locations">
                                            <p v-if="location.is_main_branch === 1">
                                                {{ $t('links.titles.contact-us-subtitles.find-us') }} <br>
                                                {{ location.address.address1 + ", " + location.address.zip + " " + location.address.city }}
                                            </p>
                                        </template>
                                    </div>
                                </li>
                                <li>
                                    <span class="icon">
                                        <img :src="contact2" alt="image">
                                    </span>
                                    <div class="text">
                                        <p>
                                            {{ $t('links.titles.contact-us-subtitles.give-us-a-call') }} <br>
                                            <a :href="'tel:' + company.contact.phone">
                                                {{ company.contact.phone }}
                                            </a>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <span class="icon">
                                        <img :src="contact3" alt="image">
                                    </span>
                                    <div class="text">
                                        <p>
                                            {{ $t('links.titles.contact-us-subtitles.send-us-a-mail') }} <br>
                                            <a :href="'mailto:' + company.contact.email.info">
                                                {{ company.contact.email.info }}
                                            </a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <!-- row end -->
            </div>
            <!-- container end -->
        </div>

        <!-- last footer -->
        <div class="bottom_footer">
            <!-- container start -->
            <div class="container">
                <!-- row start -->
                <div class="row">
                    <div class="col-md-4">
                        <p>
                            {{ $t('copyright.sender') }}
                            <br>
                            {{ $t('copyright.all-rights-reserved') }}
                        </p>
                    </div>
                    <div class="col-md-4">
                        <ul class="social_media">
                            <li v-for="social in company.socials" :key="social.social_media">
                                <a :href="social.profile_url" target="_blank">
                                    <i :class="social.icon"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <p class="developer_text">
                            {{ $t('copyright.developed-by') }} <a href="https://www.korfitz.com" target="blank">Stefan Korfitz</a>
                        </p>
                    </div>
                </div>
                <!-- row end -->
            </div>
            <!-- container end -->
        </div>

        <!-- go top button -->
        <ScrollToTop/>

        <!-- Facebook messenger -->
        <MessengerChat/>

    </footer>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import MessengerChat    from "~/footer/MessengerComponent.vue";
    import ScrollToTop      from "~/footer/ScrollToTopComponent.vue";

    // 'logoes' is an alias for 'src/assets/logoes'
    import companyLogo      from 'logoes/logo_with_white_text.png'

    // 'icons' is an alias for 'src/assets/icons'
    import contact1         from 'icons/ic_contact_01.png'
    import contact2         from 'icons/ic_contact_02.png'
    import contact3         from 'icons/ic_contact_03.png'

    export default {

        name: "Footer",
        components: {

            MessengerChat,
            ScrollToTop

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {
            return {

                companyLogo,
                contact1,
                contact2,
                contact3

            }
        },
        computed: {

            ...mapGetters({
                company: 'drivingSchool/getDrivingSchool'
            })

        },
        methods: {

            // ...

        }

    }

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    .showButton {

        display: block;
    }

</style>