<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            Nyheder
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('links.home') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/blog">
                                    {{ $t('component.navbar.blog') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link :to="{ name: 'BlogDetails', params: { id: this.id }}">
                                    {{ "#" + this.id }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section class="blog_detail_section" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
            <div class="container">
                <div class="blog_inner_pannel">
                    <div class="blog_info" data-aos="fade-up" data-aos-duration="2000">
                        <span class="date">12 Dec, 2022</span>
                        <h1>Providing IT solution that diverse business verticals lorem ipsum</h1>
                        <p>Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the
                            indu has been the industrys standard dummy text ever since the when an unknown printer took a galley
                            of type and.</p>
                        <div class="authore_block" data-aos="fade-up" data-aos-duration="1000">
                            <div class="authore">
                                <div class="img">
                                    <img src="images/new/authore_01.png" alt="image">
                                </div>
                                <div class="text">
                                    <h4>Stephan Joe</h4>
                                    <span>Author</span>
                                </div>
                            </div>
                            <div class="blog_tag">
                                <span>Software</span>
                            </div>
                        </div>
                    </div>
                    <div class="main_img" data-aos="fade-up" data-aos-duration="1500">
                        <img src="images/new/blog-detail.png" alt="image">
                    </div>
                    <div class="info" data-aos="fade-up" data-aos-duration="1500">
                        <h2>Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the
                            industrys standard dummy text ever since the when an unknown printer took a galley of type and scrambled
                            it to make a type specimen book. It has survived not only five centuries, but also the leap into
                            electronic typesetting, remaining.</p>
                        <p>Essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                            versions of Lorem Ipsum</p>
                        <ul>
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <p> <span class="icon"><i class="icofont-check-circled"></i></span> Lorem Ipsum is simply dummy </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <p> <span class="icon"><i class="icofont-check-circled"></i></span> Text of the printing and typesetting
                                    industry</p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <p> <span class="icon"><i class="icofont-check-circled"></i></span> Lorem Ipsum has been the industrys
                                    standard
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <p> <span class="icon"><i class="icofont-check-circled"></i></span> Dummy text ever since the when. </p>
                            </li>
                        </ul>
                        <h2 data-aos="fade-up" data-aos-duration="1500">Lorem Ipsum is simply dummy.</h2>
                        <p data-aos="fade-up" data-aos-duration="1500">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry lorem Ipsum has been the
                            industrys standard dummy text ever since the when an unknown printer took a galley of type and scrambled
                            it to make a type specimen book. It has survived not only five centuries, but also the leap into
                            electronic typesetting, remaining.</p>
                    </div>
                    <div class="quote_block" data-aos="fade-up" data-aos-duration="1500">
                        <span class="q_icon">“</span>
                        <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the
                            industrys standard dummy text ever since the when an unknown printer.</h3>
                        <span class="q_icon">”</span>
                    </div>
                    <div class="info" data-aos="fade-up" data-aos-duration="1500">
                        <h3>Simply dummy text lorem Ipsum is.</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been the
                            industrys standard dummy text ever since the when an unknown printer took a galley of type and scrambled
                            it to make a type specimen book. It has survived not only five centuries, but also the leap into
                            electronic typesetting, remaining.</p>
                    </div>
                    <div class="main_img" data-aos="fade-up" data-aos-duration="1500">
                        <iframe style="width: 100%; height: 500px" src="https://www.youtube.com/embed/tgbNymZ7vqY"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                    </div>
                    <div class="info" data-aos="fade-up" data-aos-duration="1500">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the
                            industrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                            electronic industry.</p>
                    </div>
                    <div class="blog_authore" data-aos="fade-up" data-aos-duration="1500">
                        <div class="social_media">
                            <h3>
                                Share this
                            </h3>
                            <ul>
                                <li>
                                    <a href="#">
                                        <i class="icofont-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="icofont-pinterest"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="row_am comment_section">
            <div class="container">
                <div class="blog_cooment_block">
                    <div class="posted_cooment">
                        <div class="section_title" data-aos="fade-up" data-aos-duration="1500">
                            <h2>3 Comments</h2>
                        </div>
                        <ul>
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <div class="authore_info">
                                    <div class="avtar">
                                        <img src="images/blog_d01.png" alt="image">
                                    </div>
                                    <div class="text">
                                        <span>30 min ago</span>
                                        <h4>John Due</h4>
                                    </div>
                                </div>
                                <div class="comment">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry lorem Ipsum has been
                                        theindustrys standard dummy text ever since the when an unknown printer. </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500" class="replay_comment">
                                <div class="authore_info">
                                    <div class="avtar">
                                        <img src="images/blog_d02.png" alt="image">
                                    </div>
                                    <div class="text">
                                        <span>50 min ago</span>
                                        <h4>Lily Joo</h4>
                                    </div>
                                </div>
                                <div class="comment">
                                    <p>
                                        Printing and typesetting industry lorem Ipsum has been the industrys standard dummy text ever since
                                        the when an unknown printer.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1500">
                                <div class="authore_info">
                                    <div class="avtar">
                                        <img src="images/blog_d03.png" alt="image">
                                    </div>
                                    <div class="text">
                                        <span>1 days ago</span>
                                        <h4>Cyrus Loy</h4>
                                    </div>
                                </div>
                                <div class="comment">
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting in dustry lorem Ipsum has been the
                                        in dustrys standard dummy text ev er since the 1500s, when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Comment block Form -->
                    <div class="comment_form_section">
                        <div class="section_title" data-aos="fade-up" data-aos-duration="1500">
                            <h2>
                                Leave a <span>comment</span>
                            </h2>
                            <p>
                                Your email address will not be published. Required fields are marked *
                            </p>
                        </div>
                        <form action="submit" data-aos="fade-up" data-aos-duration="1500">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Name *">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Email *">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Phone">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Website  ">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" placeholder="Comments"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12 text-left">
                                    <button class="btn btn_main" type="submit">POST COMMENTS <i class="icofont-arrow-right"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    </div>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapGetters }   from "vuex";

    // '~' is an alias for 'src/components'
    import Navigation       from "~/NavigationComponent";

    export default {
        name: "BlogDetails",
        head: {

            title: 'Se relevante nyheder - Køreskolen holder dig up-to-date 📰',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {

            return {

                id: this.$route.params.id

            };

        },
        computed: {

            ...mapGetters({
                story: 'blogs/getStoryDetails'
            })

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>