<!--eslint-disable-->
<template>

    <div class="blog_panel" data-aos="fade-up" data-aos-duration="1500">
        <div class="main_img">
            <router-link :to="{ name: 'BlogDetails', params: { id: story.id }}">
                <img :src="placeholderImage" alt="image">
            </router-link>
        </div>
        <template v-for="meta in story.meta">
            <div v-if="meta.i18n === $i18n.locale" class="blog_info">
                <span class="date">
                    {{ $t('views.blog.story.published-at-date') + $filter.date(meta.created_at) }}
                </span>
                <h2>
                    <router-link :to="{ name: 'BlogDetails', params: { id: story.id }}">
                        <template v-for="meta in story.meta">
                            <template v-if="meta.i18n === $i18n.locale">
                                {{ $filter.toUpper(meta.title) }}
                            </template>
                        </template>
                    </router-link>
                </h2>
                <p>
                    <template v-for="meta in story.meta">
                        <template v-if="meta.i18n === $i18n.locale">
                            {{ meta.introduction }}
                        </template>
                    </template>
                </p>
                <div class="authore_block">
                    <div class="authore">
                        <div class="img">
                            <img :src="meta.author.img_avatar" alt="image">
                        </div>
                        <div class="text">
                            <h4>
                                {{ meta.author.first_name + " " + meta.author.last_name }}
                            </h4>
                            <span>
                                {{ $t('views.blog.story.author') }}
                            </span>
                        </div>
                    </div>
                    <div class="blog_tag">
                        <template v-for="tag in story.tags">
                            <template v-for="meta in tag.meta">
                                <span v-if="meta.i18n === $i18n.locale" class="ml-2">
                                    {{ meta.tag_label }}
                                </span>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </div>

</template>
<!--eslint-disable-->
<script>

    // 'images' is an alias for 'src/assets/images'
    import placeholderImage from 'images/img_blog_01.png'

    export default {
        name: "StoryComponent",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        props: [ 'story' ],
        data() {

            return {

                placeholderImage

            };

        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>