/* eslint-disable */

import { createStore }  from "vuex";

import VuexPersist      from "vuex-persist";

import auth             from "./auth";
import blogs            from "./blogs";
import businessPartners from "./business-partners";
import businessTerms    from "./business-terms";
import contact          from "./contact";
import content          from "./content";
import courses          from "./courses";
import drivingSchool    from "./driving-school";
import employees        from "./employees";
import faqs             from "./faqs";
import loading          from "./loading";
import locales          from "./locales";
import privacyPolicy    from "./privacy-policy";
import products         from "./products";
import ratings          from "./ratings";
import signupModal      from "./signup-modal";
import tags             from "./tags";
import testimonials     from "./testimonials";

const vuexLocalStorage = new VuexPersist({
    storage: window.localStorage
});

export default createStore({

    plugins: [
        vuexLocalStorage.plugin
    ],

    modules: {

        auth,
        blogs,
        businessPartners,
        businessTerms,
        contact,
        content,
        courses,
        drivingSchool,
        employees,
        faqs,
        loading,
        locales,
        privacyPolicy,
        products,
        ratings,
        signupModal,
        tags,
        testimonials
    }

});