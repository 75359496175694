<!--eslint-disable-->
<template>

    <section class="row_am trusted_section">
        <!-- container start -->
        <div class="container">
            <div class="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2>
                    {{ $t('components.business-partners.heading') }}
                </h2>
                <p>
                    {{ $t('components.business-partners.sub-title') }}
                </p>
            </div>
            <!-- logos slider start -->
            <div class="company_logos" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <div id="company_slider" class="owl-carousel owl-theme brand-carousel">

                    <div v-for="partner in partners" class="item">
                        <div class="logo">
                            <img :src="partner.img_brand_carousel" alt="">
                        </div>
                    </div>

                </div>
            </div>
            <!-- logos slider end -->
        </div>
        <!-- container end -->
    </section>

</template>
<!--eslint-disable-->
<script>

import { mapActions, mapGetters } from "vuex";

    export default {
        name: "BusinessPartners",
        components: {

            // ...

        },
        created() {

            this.populateBusinessPartners(this.token)

        },
        mounted() {

            $(".brand-carousel").owlCarousel({

                items: 5,
                singleItem:true,
                loop: true,
                center: false,
                margin: 80,
                autoheight: true,
                lazyload: true,
                nav: false,
                dots: false,
                autoplay: true,
                autoplayTimeout: 6000,
                smartSpeed: 1000
            });

        },
        data() {

            return {

                //...
            };

        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                partners: 'businessPartners/getBusinessPartners'
            })

        },
        methods: {

            ...mapActions({

                getBusinessPartners: 'businessPartners/fetchBusinessPartners'
            }),

            populateBusinessPartners(token) {

                this.getBusinessPartners(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Business-partners state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching business-partners (catch) failed")

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>