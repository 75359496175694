<!--eslint-disable-->
<template>

    <div>

        <div class="inner_page_block white_option">

            <Navigation/>

            <!-- Bread Crumb -->
            <div class="bread_crumb" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                <div class="container">

                    <!-- animated dots -->
                    <div class="dotes_anim_bloack">
                        <div class="dots dotes_1"></div>
                        <div class="dots dotes_2"></div>
                        <div class="dots dotes_3"></div>
                        <div class="dots dotes_4"></div>
                        <div class="dots dotes_5"></div>
                        <div class="dots dotes_6"></div>
                        <div class="dots dotes_7"></div>
                        <div class="dots dotes_8"></div>
                    </div>


                    <div class="bred_text">
                        <h1>
                            {{ $t('breadcrumb.course-start.title') }}
                        </h1>
                        <ul>
                            <li>
                                <router-link to="/home">
                                    {{ $t('breadcrumb.start') }}
                                </router-link>
                            </li>
                            <li>
                                <span>»</span>
                            </li>
                            <li>
                                <router-link to="/course/start">
                                    {{ $t('breadcrumb.course-start.target') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <section class="review_section">
            <div class="container">
                <div class="row">

                    <!-- Rating box -->
                    <rating v-for="(rating, index) in ratings" :rating="rating" :key="index"/>

                </div>
            </div>
        </section>

        <section class="row_am latest_story" id="blog">
            <div class="container">
                <div class="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                    <h2>
                        {{ $t('components.future-courses.heading') }}
                    </h2>
                    <p>
                        {{ $t('components.future-courses.sub-title') }}
                    </p>
                </div>
                <div class="row">

                    <!-- course -->
                    <course v-for="(course, index) in courses" :course="course" :key="index"/>

                </div>
            </div>
        </section>

    </div>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Navigation   from "~/NavigationComponent";
    import Rating       from "~/about-us/RatingComponent";
    import Course       from "~/courses/FutureCourseComponent";

    export default {
        name: "CourseStart",
        head: {

            title: 'Klar til at tage kørekort? 🚗 Se hvornår næste hold starter?',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Navigation,
            Rating,
            Course

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {

            return {

                rating: {},
                course: {}

            };

        },
        computed: {

            ...mapGetters({
                ratings: 'ratings/getRatings',
                courses: 'courses/getCourses'
            })

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>