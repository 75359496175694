<!--eslint-disable-->
<template>

<!--    <template v-click-outside="toggleVisibility">-->
    <template>
        <prime-dialog v-model:visible="isShowing" @update:visible="toggleVisibility" modal :pt="this.modalStyling">

            <section class="info-form-section rounded-corners" :style="{ backgroundImage: `url(${background})` }">
                <div class="auto-container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="content-block">
                                <div class="sec-title light mb-30">
                                    <div class="sub-title">
                                        {{ $t('modals.sign-up.title') }}
                                    </div>
                                    <h2>
                                        {{ $t('modals.sign-up.heading') }}
                                    </h2>
                                </div>
                                <div class="text mb-30">
                                    {{ $t('modals.sign-up.sub-title') }}
                                </div>
                                <ul class="list">
                                    <li>
                                        {{ $t('modals.sign-up.selling-point.1') }}
                                    </li>
                                    <li>
                                        {{ $t('modals.sign-up.selling-point.2') }}
                                    </li>
                                    <li>
                                        {{ $t('modals.sign-up.selling-point.3') }}
                                    </li>
                                    <li>
                                        {{ $t('modals.sign-up.selling-point.4') }}
                                    </li>
                                    <li>
                                        {{ $t('modals.sign-up.selling-point.5') }}
                                    </li>
                                    <li>
                                        {{ $t('modals.sign-up.selling-point.6') }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="consult-form">
                                <h3>
                                    {{ $t('modals.sign-up.form.heading') }}
                                </h3>
                                <div class="text">
                                    {{ $t('modals.sign-up.form.sub-title') }}
                                </div>
                                <form @submit.prevent="submit">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input :placeholder="$t('modals.sign-up.form.place-holders.first-name')" type="text" v-model="form.firstName">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input :placeholder="$t('modals.sign-up.form.place-holders.last-name')" type="text" v-model="form.lastName">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input :placeholder="$t('modals.sign-up.form.place-holders.email')" type="email" v-model="form.email">
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input :placeholder="$t('modals.sign-up.form.place-holders.mobile')" type="text" v-model="form.phone">
                                        </div>
                                        <div class="form-group col-md-12">
                                            <template v-if="this.uid">
                                                <input :placeholder="$t('modals.sign-up.form.place-holders.course-category') + this.course(this.uid).category.class + ' 👉 ' + $t('modals.sign-up.form.place-holders.course-start') + $filter.dateTime(this.course(this.uid).starting_date)" type="text" readonly>
                                            </template>
                                            <template v-else>

                                                <prime-dropdown v-model="form.courseOfInterests" :options="courses" :placeholder="$t('modals.sign-up.form.place-holders.course-of-interests')" :pt="this.selectStyling">
                                                    <template #value="slotProps">
                                                        <div v-if="slotProps.value" class="flex align-items-center pt-2 pb-2 ">
                                                            <div>{{ $t('modals.sign-up.form.place-holders.course-category') + slotProps.value.category.class + " 👉 " + $t('modals.sign-up.form.place-holders.course-start') + $filter.dateTime(slotProps.value.starting_date) }}</div>
                                                        </div>
                                                        <span v-else>
                                                            {{ slotProps.placeholder }}
                                                        </span>
                                                    </template>
                                                    <template #option="slotProps">
                                                        <div class="flex align-items-center pt-2 pb-2 padding-sides">
                                                            <div>{{ "[" + slotProps.option.uid + "] " + $t('modals.sign-up.form.place-holders.course-category') + slotProps.option.category.class + " 👉 " + $t('modals.sign-up.form.place-holders.course-start') + $filter.dateTime(slotProps.option.starting_date) }}</div>
                                                        </div>
                                                    </template>
                                                </prime-dropdown>

                                            </template>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <textarea name="form_message" :placeholder="$t('modals.sign-up.form.place-holders.message')" v-model="form.message"></textarea>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <button class="btn btn_main" type="submit">
                                                <template v-if="isLoading">
                                                    <ring-spinner/>
                                                </template>
                                                <template v-else>
                                                    {{ $t('buttons.enroll') }} <i class="icofont-arrow-right"></i>
                                                </template>
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </prime-dialog>

        <prime-toast/>

    </template>

</template>
<!--eslint-disable-->
<script>

    import { mapActions, mapGetters } from "vuex";

    // 'images' is an alias for 'src/assets/images'
    import background   from 'images/backgrounds/bg_curviture_green.jpg'

    export default {
        name: "SignupModalComponent",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {

            return {

                background,

                uid: this.$route.params.uid,

                modalStyling: {
                    root: { class: 'p-dialog' },
                    header: { class: 'p-dialog-header' },
                    icons: { class: 'p-dialog-header-icons' },
                    closeButton: { class: 'p-dialog-header-icon' },
                },

                selectStyling: {
                    root: { class: 'p-dropdown' },
                    input: { class: 'p-dropdown-label' },
                    clearIcon: { class: 'p-dropdown-clear-icon' },
                    trigger: { class: 'p-dropdown-trigger-icon' },
                    loadingIcon: { class: '' },
                    panel: { class: 'p-dropdown-panel' },
                    header: { class: 'p-dropdown-header' },
                    //
                    //
                    //
                    wrapper: { class: 'p-dropdown-header' },
                },

                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    courseOfInterests: this.$route.params.uid ? this.$route.params.uid : '',
                    message: '',
                }
            };

        },
        computed: {

            ...mapGetters({
                languages: 'locales/getLocales',
                token: 'auth/authenticated',
                isShowing: 'signupModal/visibility',
                isLoading: 'loading/status',
                course: 'courses/getCourseDetails',
                courses: 'courses/getCourses'
            }),

        },
        methods: {

            ...mapActions({
                loading: 'loading/setLoading',
                show: 'signupModal/setState',
                signUp: 'courses/signupToCourse'
            }),

            submit() {

                const payload = {
                    token: this.token,
                    form: this.form,
                };

                // validate form inputs here

                // set loading til true
                this.loading(true)

                this.signUp(payload)

                    .then(() => {

                        // set loading to false signup
                        this.loading(false)

                        // close dialog
                        this.closeSignupDialog()

                        // show SUCCESS toast
                        this.$toast.add({

                            severity:   'success',
                            summary:    this.$t('modals.sign-up.form.toast.success.summary'),
                            detail:     this.$t('modals.sign-up.form.toast.success.detail'),
                            life:       3000
                        });

                    })

                    .catch(() => {

                        // show FAIL toast
                        this.$toast.add({

                            severity:   'error',
                            summary:    this.$t('modals.sign-up.form.toast.fail.summary'),
                            detail:     this.$t('modals.sign-up.form.toast.fail.detail'),
                            life:       3000
                        });

                    })

            },

            closeSignupDialog() {

                this.show(false)

            },

            toggleVisibility() {

                if (this.isShowing) {

                    // Close the customizer
                    this.show(false)

                } else {

                    // Open the customizer
                    this.show(true)

                }

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>