<!--eslint-disable-->
<template>

    <template v-if="devMode === 'production'">

        <!-- Messenger Chatplugin Code -->
        <div id="fb-root"></div>

        <!-- Your Chatplugin code -->
        <div id="fb-customer-chat" class="fb-customerchat"></div>

    </template>

</template>
<!--eslint-disable-->
<script>

    export default {

        name: "MessengerChat",
        created() {

            // ...

        },
        mounted() {

            if (this.devMode === "production") {

                $( document ).ready(function() {

                    var chatbox = document.getElementById('fb-customer-chat');
                    chatbox.setAttribute("page_id", "110222521888415");
                    chatbox.setAttribute("attribution", "biz_inbox");

                    window.fbAsyncInit = function() {
                        FB.init({
                            xfbml            : true,
                            version          : 'v15.0'
                        });
                    };

                    (function(d, s, id) {
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) return;
                        js = d.createElement(s); js.id = id;
                        js.src = 'https://connect.facebook.net/da_DK/sdk/xfbml.customerchat.js';
                        fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'facebook-jssdk'));

                });
            }

        },
        data() {
            return {

                devMode: process.env.VUE_APP_MODE

            }
        },
        computed: {

            // ...

        },
        methods: {

            // ...

        }

    }

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>
