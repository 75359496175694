/* eslint-disable */
const LOADING_STATE= 'LOADING_STATE'
const PRELOADING_STATE  = 'PRELOADING_STATE'

export default {

    namespaced: true,

    state : {
        preloadComplete: false,
        status: false
    },

    actions : {

       /**
        * Action to set the PRE-loading state
        *
        * @param commit
        * @param preLoading
        */
       setPreloading ({ commit }, preLoading) {
           commit(PRELOADING_STATE, preLoading)
       },
        
        /**
         * Action to set the loading state
         *
         * @param commit
         * @param isLoading
         */
        setLoading ({ commit }, isLoading) {
            commit(LOADING_STATE, isLoading)
        }
    },

    getters : {

        preloadComplete(state) {

            return state.preloadComplete
        },
        
        status(state) {
            
            return state.status
        }
    },

    mutations : {
        
        [PRELOADING_STATE]: (state, preLoading) => {
            state.preloadComplete = preLoading;
        },

        [LOADING_STATE]: function (state, isLoading) {
            state.status = isLoading
        }
    }
}
