/* eslint-disable */

import { createRouter, createWebHistory } from "vue-router";

/**
 * importing middlewares
 */
import pipeline         from '@/middleware/pipeline';
import auth             from "@/middleware/auth";

/**
 * importing store
 */
import store            from "*/store";

/**
 * importing views
 */

/* Error 404 */
import NotFound         from "^/error/404";
/* Airport */
import Airport          from "^/Airport.vue";

import About            from "^/home/About.vue";
import Blog             from "^/home/Blog.vue";
import BlogDetails      from "^/home/BlogDetails.vue";
import BusinessTerms    from "^/home/BusinessTerms.vue";
import Contact          from "^/home/Contact.vue";
import CourseStart      from "^/home/CourseStart.vue";
import CourseDetails    from "^/home/CourseDetails.vue";
import FAQ              from "^/home/FAQ.vue";
import Home             from "^/home/Home.vue";
import Info             from "^/home/Info.vue";
import Prices           from "^/home/Prices.vue";
import PrivacyPolicy    from "^/home/PrivacyPolicy.vue";

const routes = [

    // Dashboard
    {
        path: "/",
        component: Airport,
        redirect: "/home",
        children: [

            // Error 404
            {
                path: '/404',
                component: NotFound,
                meta: {

                    middleware: [auth]
                }
            },
            {
                path: "/:catchAll(.*)",
                name: "NotFound",
                component: NotFound,
                meta: {

                    middleware: [auth]
                }
            },

            // About us
            {
                path: "/about",
                name: "About",
                component: About,
                meta: {

                    //middleware: []
                }
            },

            // Blog
            {
                path: "/blog",
                name: "Blog",
                component: Blog,
                meta: {

                    //middleware: [auth]
                }
            },

            // Blog Details
            {
                path: "/blog/:id",
                name: "BlogDetails",
                component: BlogDetails,
                meta: {

                    //middleware: [auth]
                }
            },

            // Business Terms
            {
                path: "/business-terms",
                name: "BusinessTerms",
                component: BusinessTerms,
                meta: {

                    //middleware: [auth]
                }
            },

            // Contact
            {
                path: "/contact",
                name: "Contact",
                component: Contact,
                meta: {

                    //middleware: [auth]
                }
            },

            // Course Start
            {
                path: "/course/start",
                name: "CourseStart",
                component: CourseStart,
                meta: {

                    //middleware: [auth]
                }
            },

            // Course Details
            {
                path: "/course/:uid",
                name: "CourseDetails",
                component: CourseDetails,
                meta: {

                    //middleware: [auth]
                }
            },

            // FAQ
            {
                path: "/faq",
                name: "FAQ",
                component: FAQ,
                meta: {

                    //middleware: [auth]
                }
            },

            // HOME
            {
                path: "/home",
                name: "Home",
                component: Home,
                meta: {

                    //middleware: [auth]
                }
            },

            // Info
            {
                path: "/info",
                name: "Info",
                component: Info,
                meta: {

                    //middleware: [auth]
                }
            },

            // PRICES
            {
                path: "/prices",
                name: "Prices",
                component: Prices,
                meta: {

                    //middleware: [auth]
                }
            },

            // PRIVACY POLICY
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
                meta: {

                    //middleware: [auth]
                }
            }

        ]
    },
];

const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "current",
    scrollBehavior(to, from, savedPosition) {

        // always scroll to top, when navigating to a new page
        return { top: 0 }
    }

});

router.beforeEach((to, from, next) => {

    // // Get the page title from the route meta-data that we have defined
    // const title = to.meta.title
    //
    // // If the route has a title, set it as the page title of the document/page
    // if (title) {
    //
    //     document.title = title
    // }

    if (!to.meta.middleware) {

        return next()
    }

    const middleware = to.meta.middleware

    const context = {

        to,
        from,
        store,
        next
    }

    //console.log(translator('app.name'))

    return middleware[0]({

        ...context, next: pipeline(context, middleware, 1)
    })
})

export default router;