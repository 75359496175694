<!--eslint-disable-->
<template>

    <div class="go_top" id="Gotop" :class="{ showButton: show }" v-on:click="goToTop">
        <span>
            <i class="icofont-arrow-up"></i>
        </span>
    </div>

</template>
<!--eslint-disable-->
<script>

    export default {

        name: "ScrollUp",
        created() {

            // ...

        },
        mounted() {

            window.addEventListener("scroll", this.handleScroll);

        },
        data() {
            return {

                show: false,

            }
        },
        computed: {

            // ...

        },
        methods: {

            handleScroll() {

                const currentScrollPosition = window.scrollY

                this.show = currentScrollPosition !== 0;

            },

            goToTop() {

                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            }

        }

    }

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    .showButton {

        display: block;
    }

</style>
