<!--eslint-disable-->
<template>

    <div>
        <Banner/>
        <UniqueFeatures/>
        <Status/>
        <Prices/>
        <FutureCourses/>
        <Testimonials/>
        <BusinessPartners/>
    </div>

</template>
<!--eslint-disable-->
<script>

    // importing map(s)
    import { mapGetters } from "vuex";

    // '~' is an alias for 'src/components'
    import Banner           from "~/home/BannerComponent";
    import UniqueFeatures   from "~/home/UniqueFeaturesComponent";
    import Status           from "~/home/StatusComponent";
    import Prices           from "~/_shared/PricesComponent";
    import FutureCourses    from "~/_shared/FutureCoursesComponent";
    import Testimonials     from "~/_shared/TestimonialComponent";
    import BusinessPartners from "~/_shared/BusinessPartnerComponent";

    export default {
        name: "Home",
        head: {

            title: '🚀 Nemt, hurtigt & billigt kørekort hos tune trafikskole',
            meta: [
                {
                    name: 'description',
                    content: '',
                },
            ],

        },
        components: {

            Banner,
            UniqueFeatures,
            Status,
            Prices,
            FutureCourses,
            Testimonials,
            BusinessPartners

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {

            return {

                //...

            };

        },
        computed: {

            ...mapGetters({
                websiteMeta: 'content/getContent'
            })

        },
        methods: {

            // ...

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...

</style>