<!--eslint-disable-->
<template>

    <section class="what_coustomer_says">
        <div class="container">
            <div class="section_title" data-aos="fade-up" data-aos-duration="1500">
                <h2>
                    {{ $t('components.testimonials.heading') }}
                </h2>
                <p>
                    {{ $t('components.testimonials.sub-title') }}
                </p>
            </div>
            <div id="coustomer_slider_white" class="owl-carousel owl-theme testimonials-carousel" data-aos="fade-in" data-aos-duration="1500">

                <div v-for="testimonial in testimonials" class="item">
                    <div v-if="testimonial.published_online === 1" class="coustomer_slide_box">
                        <div class="rating">
                            <span>
                                <i class="icofont-star"></i>
                            </span>
                            <span>
                                <i class="icofont-star"></i>
                            </span>
                            <span>
                                <i class="icofont-star"></i>
                            </span>
                            <span>
                                <i class="icofont-star"></i>
                            </span>
                            <span>
                                <i class="icofont-star"></i>
                            </span>
                        </div>

                        <template v-for="meta in testimonial.meta">
                            <p v-if="meta.i18n === $i18n.locale" class="coustomer_slide_box_review">
                                {{ meta.message }}
                            </p>
                        </template>
                        <div class="avtar_profil">
                            <div class="avatr">
                                <img :src="testimonial.written_by.img_avatar" alt="">
                            </div>
                            <div class="text">
                                <h3>
                                    {{ testimonial.written_by.first_name + " " + testimonial.written_by.last_name }}
                                </h3>
                                <span>
                                    Careative inc.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- REVIEW SUMMARY TODO -->
<!--            <div class="review_summery">-->
<!--                <div class="rating">-->
<!--                    <span>-->
<!--                        <i class="icofont-star"></i>-->
<!--                    </span>-->
<!--                    <span>-->
<!--                        <i class="icofont-star"></i>-->
<!--                    </span>-->
<!--                    <span>-->
<!--                        <i class="icofont-star"></i>-->
<!--                    </span>-->
<!--                    <span>-->
<!--                        <i class="icofont-star"></i>-->
<!--                    </span>-->
<!--                    <span>-->
<!--                        <i class="icofont-star"></i>-->
<!--                    </span>-->
<!--                </div>-->
<!--                <p>-->
<!--                    <span> 5.0 / 5.0 - </span>-->
<!--                    <a href="testimonial.html">-->
<!--                        3689 Total User Reviews <i class="icofont-arrow-right"></i>-->
<!--                    </a>-->
<!--                </p>-->
<!--            </div>-->
        </div>
    </section>

</template>
<!--eslint-disable-->
<script>

import { mapActions, mapGetters } from "vuex";

    import 'owl.carousel'

    export default {
        name: "Testimonials",
        components: {

            // ...

        },
        created() {

            this.populateTestimonials(this.token)

        },
        mounted() {

            $(".testimonials-carousel").owlCarousel({

                items: 3,
                singleItem:true,
                loop: true,
                center: false,
                margin: 30,
                autoheight: true,
                lazyload: true,
                nav: false,
                dots: true,
                autoplay: true,
                autoplayTimeout: 6000,
                smartSpeed: 1000
            });

        },
        data() {
            return {

                // ...

            }
        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated',
                testimonials: 'testimonials/getTestimonials'
            })

        },
        methods: {

            ...mapActions({

                getTestimonials: 'testimonials/fetchTestimonials'
            }),

            populateTestimonials(token) {

                this.getTestimonials(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Testimonials state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching testimonials (catch) failed")

                    })

            }

        }
    };

</script>
<!--eslint-disable-->
<style scoped lang="scss">

    // ...
    .coustomer_slide_box_review {

        //height: 400px;
    }

</style>